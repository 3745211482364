import { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import { subscriptionOptions } from "../../../utils/variables/subscriptionOptions";
import showAlert from "../../lib/Alerts/sweetAlert";
import SubscriptionService from "../../../services/subscription.service";

const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  period: "required",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const subscriptionService = new SubscriptionService();

export default function AddSubscription({
  closeModalFunction,
  onSuccessFunction = () => { },
}) {
  const [subscriptionData, setSubscriptionData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    period: "",
  });
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});

  function handleInputChange(value, fields) {
    setSubscriptionData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }

  const CloseModalAddSubscription = () => {
    closeModalFunction();
  };

  const validation = new Validator(subscriptionData, validationRules);
  validation.setAttributeNames({
    firstname: "nom",
    lastname: "prenom",
    email: "email",
    phone: "téléphone",
    period: "period",
  });

  async function addNewSubscription() {
    if (validation.passes()) {
      const newSubscription = {
        "firstname": subscriptionData.firstname,
        "email": subscriptionData.email,
        "phone": subscriptionData.phone,
        "lastname": subscriptionData.lastname,
        "period": subscriptionData.period
      }
      try {
        await subscriptionService.add(newSubscription);
        showAlert({
          title: "Abonnement ajoutée avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAddSubscription();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur est survenue lors de la création",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }

  return (
    <Modal
      onCancelFunction={CloseModalAddSubscription}
      onCloseFunction={CloseModalAddSubscription}
      submitFunction={addNewSubscription}
      cancelText="annuler"
      dialogTitle={
        <BreadcrumbsLink
          firstLinkTitle="Abonnements"
          href="/subscriptions"
          activatedLinkTitle="Ajouter un abonnement"
          color="titleModal"
        />
      }
      applyTitle="Sauvgarder"
      isOpened={true}
      dialogContentChild={
        <div className="row">
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Nom"
              type="text"
              name="firstname"
              value={subscriptionData.firstname}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.firstname && (
              <AlertMessages
                message={validationErrors.firstname}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Prénom"
              type="text"
              name="lastname"
              value={subscriptionData.lastname}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.lastname && (
              <AlertMessages
                message={validationErrors.lastname}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Email"
              type="email"
              name="email"
              value={subscriptionData.email}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.email && (
              <AlertMessages
                message={validationErrors.email}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <InputGroup
              classNames="form-control-input-group-add"
              title="Téléphone"
              type="text"
              name="phone"
              value={subscriptionData.phone}
              onChangeFunction={handleInputChange}
            />
            {validationErrors && validationErrors.phone && (
              <AlertMessages
                message={validationErrors.phone}
                messageType="error"
              />
            )}
          </div>
          <div className="col-6">
            <SelectForm
              onChangeFunction={handleInputChange}
              options={subscriptionOptions}
              name="period"
              value={subscriptionData.period}
              labelFilter="Periode d’abonnement"
              classNames="addUser_select"
              titleSelect="Periode d’abonnement"
              MenuProps={MenuProps}
            />
            {validationErrors && validationErrors.period && (
              <AlertMessages
                message={validationErrors.period}
                messageType="error"
              />
            )}
          </div>
        </div>
      }
    />
  );
}
