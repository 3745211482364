import React from "react";
import "./button.css";

export default function Button({
  title,
  iconStart = "",
  iconEnd = "",
  classNames = "",
  color = "",
  type = "button",
  onClickFunction = () => {},
}) {
  return (
    <button
      type={type}
      className={`${color} ${classNames}`}
      onClick={onClickFunction}
    >
      {iconStart} {title} {iconEnd}
    </button>
  );
}
