import react, { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import UserService from "../../../services/user.service";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import { villes } from "../../../utils/variables/villesOptions";
import { options } from "../../../utils/variables/roleOptions";
import { environment } from "../../../environments/environment";
import { Pencil } from "react-bootstrap-icons";
const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  username: "required",
  city: "required|string",
  address: "required",
  role: "required",
};
const userService = new UserService();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function EditUser({
  user = {},
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const baseUrl = environment.API_URL;
  const [userData, setUserData] = useState(user);
  const [validationErrors, setValidationErrors] = useState({});
  const [imageUrl, setImageUrl] = useState(
    baseUrl + "users/" + user._id + "/image"
  );
  Validator.useLang("fr");

  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    firstname: "prénom",
    lastname: "nom",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
    city: "City",
    address: "Adress",
    role: "Role",
    password: "mot de passe",
  });
  const CloseModalAddUser = () => {
    closeModalFunction();
  };
  async function updateUser() {
    if (validation.passes()) {
      const updatedUser = new FormData();
      updatedUser.append("path", userData.path);
      updatedUser.append("firstname", userData.firstname);
      updatedUser.append("lastname", userData.lastname);
      updatedUser.append("email", userData.email);
      updatedUser.append("phone", userData.phone);
      updatedUser.append("username", userData.username);
      updatedUser.append("country", "Tunisie");
      updatedUser.append("city", userData.city);
      updatedUser.append("password", userData.password);
      updatedUser.append("address", userData.address);
      updatedUser.append("role", userData.role);
      try {
        await userService.updateUser(user._id, updatedUser);
        showAlert({
          title: "Utilisateur modifié avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAddUser();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur de modification est survenue",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserData((prevState) => {
      return {
        ...prevState,
        path: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {}, [userData]);

  return (
    <>
      <Modal
        onCancelFunction={CloseModalAddUser}
        onCloseFunction={CloseModalAddUser}
        submitFunction={updateUser}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Utilisateurs"
            href="/users"
            activatedLinkTitle="Modifier Un utilisateur"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="imageUpload">
                    <Pencil className="edit_avatar_img" />
                  </label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="clock"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </div>
              </div>
              <InputGroup
                classNames="form-control-input-group-add"
                title="Nom"
                type="text"
                name="lastname"
                value={userData.lastname}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.lastname && (
                <AlertMessages
                  message={validationErrors.lastname}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Prénom"
                type="text"
                name="firstname"
                value={userData.firstname}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.firstname && (
                <AlertMessages
                  message={validationErrors.firstname}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Téléphone"
                type="tel"
                value={userData.phone}
                onChangeFunction={handleInputChange}
                name="phone"
              />
              {validationErrors && validationErrors.phone && (
                <AlertMessages
                  message={validationErrors.phone}
                  messageType="error"
                />
              )}

              {/* <InputGroup
                classNames="form-control-input-group-add"
                title="Mot de passe"
                type="password"
                value={userData.password}
                onChangeFunction={handleInputChange}
                name="password"
              />
              {validationErrors && validationErrors.password && (
                <AlertMessages
                  message={validationErrors.password}
                  messageType="error"
                />
              )} */}
            </div>
            <div className="col-6">
              <InputGroup
                classNames="form-control-input-group-add"
                title="Email"
                type="email"
                value={userData.email}
                onChangeFunction={handleInputChange}
                name="email"
              />
              {validationErrors && validationErrors.email && (
                <AlertMessages
                  message={validationErrors.email}
                  messageType="error"
                />
              )}
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Username"
                type="text"
                value={userData.username}
                onChangeFunction={handleInputChange}
                name="username"
              />
              {validationErrors && validationErrors.username && (
                <AlertMessages
                  message={validationErrors.username}
                  messageType="error"
                />
              )}
              {userData.role === "admin" || userData.role === "editeur" ? (
                <>
                  <SelectForm
                    onChangeFunction={handleInputChange}
                    options={options}
                    value={userData.role}
                    labelFilter="Role"
                    name="role"
                    classNames="addUser_select"
                    titleSelect="Role"
                  />
                  {validationErrors && validationErrors.role && (
                    <AlertMessages
                      message={validationErrors.role}
                      messageType="error"
                    />
                  )}
                </>
              ) : (
                ""
              )}
              <SelectForm
                disabled={true}
                onChangeFunction={handleInputChange}
                value={""}
                name="country"
                labelFilter="Tunisie"
                classNames="addUser_select"
                titleSelect="Pays"
              />
              <SelectForm
                onChangeFunction={handleInputChange}
                options={villes}
                name="city"
                value={userData.city}
                labelFilter="Ville"
                classNames="addUser_select"
                titleSelect="Ville"
                MenuProps={MenuProps}
              />
              {validationErrors && validationErrors.city && (
                <AlertMessages
                  message={validationErrors.city}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Adresse"
                type="text"
                name="address"
                value={userData.address}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.address && (
                <AlertMessages
                  message={validationErrors.address}
                  messageType="error"
                />
              )}
            </div>
          </div>
        }
      />
    </>
  );
}
