import { useEffect, useState } from "react";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import { villes } from "../../../utils/variables/villesOptions";
import { environment } from "../../../environments/environment";
import SalleService from "../../../services/salle.service";
import showAlert from "../../lib/Alerts/sweetAlert";
import dataNotFound from "../../assets/images/dataNotFound.png";
import { Box } from "@mui/material";
import TableContent from "../../lib/table/table";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const columns = [
  "Nom",
  "Prénom",
  "E-mail",
  "Téléphone",
  "Rejoindre",
  "Periode d’abonnement",
];
export default function ArchivedDetailsSalle({
  salle = {},
  closeModalFunction,
}) {
  const baseUrl = environment.API_URL;
  const salleService = new SalleService();
  const [userData, setUserData] = useState(salle);
  const [disabledSalle, setDisabledSalle] = useState([]);

  const [imageUrl] = useState(baseUrl + "users/" + salle._id + "/image");
  const CloseModaldetailsSalle = () => {
    closeModalFunction();
  };
  const formatDate = (createdAt) => {
    const formattedDate = new Date(createdAt).toLocaleDateString("en-GB");
    return formattedDate;
  };
  const getDisabledSalle = async () => {
    try {
      await salleService.getDisabledSalle(salle._id).then((response) => {
        const data = response.data;
        setDisabledSalle(data);
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const displaySallesData = () => {
    const subsList = [];

    disabledSalle[0]?.ssub?.forEach((salleSubscription) => {
      console.log(salleSubscription);
      const formattedDate = formatDate(salleSubscription.createdAt);
      subsList.push([
        <div>{salleSubscription.lastname}</div>,
        <div>{salleSubscription.firstname}</div>,
        <div>{salleSubscription.email}</div>,
        <div>{salleSubscription.phone}</div>,
        formattedDate,
        <div className="fw-bolder">{salleSubscription.period}</div>,
      ]);
    });

    return subsList;
  };

  useEffect(() => {
    getDisabledSalle();
  }, []);

  return (
    <>
      <Modal
        onCancelFunction={CloseModaldetailsSalle}
        onCloseFunction={CloseModaldetailsSalle}
        submitFunction={CloseModaldetailsSalle}
        cancelText="retour"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Agents"
            href="/agents"
            activatedLinkTitle="détails d'un agent"
            color="titleModal"
          />
        }
        applyTitle=""
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="avatar-preview">
                  <div
                    id="clock"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </div>
              </div>
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Nom de l'agent"
                type="text"
                name="firstname"
                value={userData.firstname}
              />

              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Téléphone"
                type="tel"
                value={userData.phone}
                name="phone"
              />
            </div>
            <div className="col-6">
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Email"
                type="email"
                value={userData.email}
                name="email"
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Username"
                type="text"
                value={userData.username}
                name="username"
              />
              <SelectForm
                disabled={true}
                options={villes}
                name="city"
                value={userData.city}
                labelFilter="Ville"
                classNames="addUser_select"
                titleSelect="Ville"
                MenuProps={MenuProps}
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Adresse"
                type="text"
                name="address"
                value={userData.address}
              />
            </div>
            <h5 className="py-3">
              Abonnements:{" "}
              {disabledSalle?.length === 0 ? 0 : disabledSalle[0].ssub?.length}
            </h5>
            {disabledSalle?.length === 0 ? (
              <div className="text-center">
                <img className="w-25" src={dataNotFound} alt="Data Not Found" />
              </div>
            ) : (
              <Box
                sx={{
                  border: "1px solid gainsboro",
                  borderRadius: "12px",
                }}
              >
                {disabledSalle && (
                  <TableContent
                    columns={columns}
                    data={displaySallesData(disabledSalle)}
                    rowsPerPage={10}
                    totalRows={disabledSalle.ssub?.length}
                    className="table_affect_adherent"
                  />
                )}
              </Box>
            )}
          </div>
        }
      />
    </>
  );
}
