import Swal from "sweetalert2";
export default function showAlert({
  text = "",
  title = "",
  iconType = "",
  confirmButtonText = "",
  textSuccess = "",
  titleSuccess = "",
  iconSuccess = "",
  cancelButtonText = "",
  confirmFunction = () => {},
}) {
  Swal.fire({
    title: title,
    text: text,
    icon: iconType,
    confirmButtonColor: "#14ca81",
    timer: 4000,
    showCancelButton: cancelButtonText !== "",
    showConfirmButton: confirmButtonText !== "",
    cancelButtonColor: confirmButtonText !== "" ? "#658292" : "#14ca81",
    confirmButtonText: confirmButtonText,
    cancelButtonText: cancelButtonText,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: titleSuccess,
        text: textSuccess,
        icon: iconSuccess,
        confirmButtonColor: "#14ca81",
      });
      confirmFunction();
    } else {
    }
  });
}
