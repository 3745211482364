import React, { useState } from "react";
import Input from "../lib/input/input";
import "../assets/css/login.css";
import Card from "react-bootstrap/Card";
import logoDryfit from "../assets/images/logoDryFit.png";
import Button from "../lib/button/button";
import { ArrowClockwise, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import ForgetPasswordService from "../../services/forgetPassword.service";
import NumberCodeInput from "../lib/NumberCodeInput/numberCodeInput";
const forgetPasswordService = new ForgetPasswordService();
export default function VerifyCode() {
  const navigate = useNavigate();
  const [isComplete, setIsComplete] = useState(false);
  const [token, setToken] = useState("");
  const [errMsg, setErrMsg] = useState({
    token: "",
  });
  async function codeVerified() {
    try {
      await forgetPasswordService
        .verifyCode(token)
        .then(() => {
          return navigate("/changePassword");
        })
        .catch((error) => {
          setErrMsg((prevErrMsg) => ({
            ...prevErrMsg,
            token: error.response.data.message,
          }));
        });
    } catch (error) {
      console.error(error);
    }
  }
  const toggleToLogin = () => {
    return navigate(`/login`);
  };
  return (
    <div className="login-background">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card>
          <Card.Body>
            <div className="text-center">
              <img className="pt-4 " src={logoDryfit} />
            </div>
            <h5 className="py-5 text-center">
              Un code de vérification a été envoyé à votre <br />
              adresse e-mail. Veuillez saisir le code ici et créer un nouveau
              mot de passe.
            </h5>
            <div className="d-flex flex-column align-items-center">
              <NumberCodeInput setToken={setToken} />
              <p className="text-danger fw-bolder pt-3">{errMsg.token}</p>
              <Button
                title={"Créer un nouveau mot de passe"}
                color="success"
                classNames="formButton mt-4"
                onClickFunction={codeVerified}
                disabled={isComplete}
              />
              <Button
                title={"Re-envoyer le code"}
                classNames="forgetPasswordBtn my-3"
                // onClickFunction={toggleToLogin}
                iconEnd={<ArrowClockwise />}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
