import React from "react";
import "./navLinkItem.css";
import { NavLink } from "react-router-dom";
export default function NavLinkItem({
  title = "",
  navigateTo = "",
  iconStart = "",
  isOpened = true,
}) {
  const activeUrl = window.location.pathname;
  return (
    <li className="nav-item m-0">
      <NavLink
        to={`${navigateTo}`}
        className={`nav-link collapsed ${activeUrl.includes(navigateTo) ? "nav-link-active" : ""
          }`}
      >
        {isOpened ? (
          <span className="sidebar_navlink ps-2">{title}</span>
        ) : (
          iconStart
        )}
      </NavLink>
    </li>
  );
}
