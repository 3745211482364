export const exerciceCategories = [
  "Abdominaux",
  "Adducteurs",
  "Biceps",
  "Cardio",
  "Dorsaux",
  "Epaules",
  "Fessiers",
  "Ischio-Jambiers",
  "Lombaires",
  "Mollets",
  "Pectoraux",
  "Quadriceps",
  "Triceps",
];
