import { CheckCircle, Pen, PlusCircle, Search } from "react-bootstrap-icons";
import Button from "../lib/button/button";
import SelectForm from "../lib/select/select";
import TableContent from "../lib/table/table";
import { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import MenuContent from "../lib/Menu/menu";
import InputGroup from "../lib/input-group/input-group";
import { environment } from "../../environments/environment";
import "../assets/css/global.css";
import AddExercice from "../components/Exercice/addExercice";
import ExerciceService from "../../services/exercice.service";
import { exerciceCategories } from "../../utils/variables/categories";
import Modal from "../components/modal/modal";
import DatePicker from "../lib/datePicker/datePicker";
import { addDays } from "date-fns";
import { fr } from "date-fns/locale";
import EditExercice from "../components/Exercice/editExercice";
import DetailsExercice from "../components/Exercice/detailsExercice";
import { useSelector } from "react-redux";

const columns = ["Aperçu", "Nom", "Catégorie ", "Ajouté par", "Ajouté le", ""];
const defaultDateRange = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
    locale: fr,
  },
];
export default function Exercices() {
  const connectedUser = useSelector((store) => store.user.user);
  const baseUrl = environment.API_URL;
  const exerciceService = new ExerciceService();
  const [originalExercice, setOriginalExercice] = useState([]);
  const [exercices, setExercices] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    categories: "",
    date: "",
    state: "",
    search: "",
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState("");
  useEffect(() => {
    displayExercices();
  }, []);

  useEffect(() => {
    filter();
  }, [filterOptions]);

  const filter = () => {
    let filtredData = originalExercice;
    if (filterOptions.categories !== "") {
      filtredData = filtredData.filter(
        (exercice) => exercice[2] === filterOptions.categories
      );
    }

    if (filterOptions.date !== "") {
      const startDate = filterOptions.date[0].startDate;
      const endDate = filterOptions.date[0].endDate;
      filtredData = filtredData.filter((exercice) => {
        const dateParts = exercice[4].split("/");
        const createdAt = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );

        return createdAt >= startDate && createdAt <= endDate;
      });
    }
    if (filterOptions.search !== "") {
      filtredData = filtredData.filter((exercice) => {
        return (
          exercice &&
          JSON.stringify(exercice)
            .toLowerCase()
            .includes(filterOptions.search.toLowerCase())
        );
      });
    }
    setExercices(filtredData);
  };

  function search(searchQuery) {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        search: searchQuery,
      };
    });
  }

  const formatDate = (createdAt) => {
    const formattedDate = new Date(createdAt).toLocaleDateString("en-GB");
    return formattedDate;
  };

  const displayExercices = async () => {
    try {
      await exerciceService
        .getAllExercices()
        .then((response) => {
          const data = response.data.map((exercice) =>
            formatExerciceData(exercice)
          );
          setExercices(data);
          setOriginalExercice(data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const formatExerciceData = (exercice) => {
    const formattedDate = formatDate(exercice.createdAt);
    const menuOptions = [
      <div>
        {connectedUser?.role !== "coach" && (
          <Button
            title={"modifier"}
            classNames="bg-white border-0 itemBtn"
            onClickFunction={() => {
              setOpenModal(
                <EditExercice
                  closeModalFunction={closeModal}
                  onSuccessFunction={displayExercices}
                  exercice={exercice}
                />
              );
            }}
          />
        )}
      </div>,
      <div>
        <Button
          title={"détails"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => {
            setOpenModal(
              <DetailsExercice
                closeModalFunction={closeModal}
                exercice={exercice}
              />
            );
          }}
        />
      </div>,
    ];
    return [
      <img
        src={baseUrl + "exercices/" + exercice._id + "/SimpleImage"}
        className="exercice_img"
      />,
      exercice.name,
      exercice.category,
      exercice.addedBy.username,
      formattedDate,
      <MenuContent
        btnContent={<CheckCircle className="icon_actions" />}
        menuItemOptions={menuOptions}
      />,
    ];
  };

  const filteringCategories = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        categories: value,
      };
    });
  };

  const closeModal = () => {
    setOpenModal("");
  };

  const resetFilters = () => {
    setFilterOptions({
      categories: "",
      date: "",
      state: "",
      search: "",
    });
  };

  const openModalFilterDate = () => {
    setOpenFilter(true);
  };

  const closeModalFilterDate = () => {
    setOpenFilter(false);
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates) {
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          date: selectedDates,
        };
      });
    }
  };

  return (
    <div className="me-2">
      <div className="fw-bolder fs-4 pt-3">
        <a href="/exercices" className="text-decoration-none text-black">
          Exercices
        </a>
      </div>
      <div>
        <InputGroup
          classNames="form-control-input-group-search "
          type={"text"}
          placeholder="Recherche"
          iconStart={<Search />}
          input_style="inputStyleSearch"
          value={filterOptions.search}
          onChangeFunction={search}
        />
      </div>
      <div className="d-flex align-items-center mt-4">
        <div className="d-flex justify-content-start align-items-center pe-5">
          <Button
            title={"Ajouté le"}
            classNames="modalButton me-3"
            onClickFunction={openModalFilterDate}
          />
          <Modal
            onCancelFunction={closeModalFilterDate}
            onCloseFunction={closeModalFilterDate}
            submitFunction={closeModalFilterDate}
            applyTitle="filtrer"
            isOpened={openFilter}
            cancelText="annuler"
            dialogContentChild={
              <DatePicker
                onChangeFunction={handleDateChange}
                date={
                  filterOptions.date !== ""
                    ? filterOptions.date
                    : defaultDateRange
                }
              />
            }
          />
          <SelectForm
            onChangeFunction={filteringCategories}
            options={exerciceCategories}
            value={filterOptions.categories}
            labelFilter="Catégorie"
            classNames="filtered_adherent_select "
            selectStyle="label_select_status"
          />
        </div>
        <div>
          <Button
            title={"Effacer filtres"}
            color="secondary"
            classNames="clearFilterButton p-0"
            onClickFunction={resetFilters}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2">
        {connectedUser?.role !== "coach" && (
          <Button
            title={"Ajouter"}
            color="success"
            classNames="add_button"
            iconStart={<PlusCircle className="icon_add" />}
            onClickFunction={() => {
              setOpenModal(
                <AddExercice
                  closeModalFunction={closeModal}
                  onSuccessFunction={displayExercices}
                />
              );
            }}
          />
        )}
      </div>
      <TableContent
        columns={columns}
        data={exercices}
        rowsPerPage={5}
        totalRows={exercices.length}
      />
      {openModal}
    </div>
  );
}
