import react, { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import UserService from "../../../services/user.service";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import { options } from "../../../utils/variables/roleOptions";
import { villes } from "../../../utils/variables/villesOptions";
import { Pencil } from "react-bootstrap-icons";

const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  username: "required",
  city: "required|string",
  address: "required",
  role: "required",
  password: "required|string|min:8",
  confirmedPassword: "required|same:password",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const userService = new UserService();

export default function AddUser({
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    username: "",
    city: "",
    address: "",
    role: "",
    password: "",
    confirmedPassword: "",
    path: "",
  });
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});
  const [imageUrl, setImageUrl] = useState(
    "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
  );
  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }

  const CloseModalAddUser = () => {
    closeModalFunction();
  };
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    firstname: "prénom",
    lastname: "nom",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
    city: "ville",
    address: "adresse",
    role: "role",
    password: "mot de passe",
    confirmedPassword: "confirmer mot de passe",
  });
  async function generateUsername() {
    try {
      if (userData.lastname && userData.firstname !== "") {
        const newUSerName = await userService.generateUserName(userData);
        setUserData((prevState) => ({
          ...prevState,
          username: newUSerName.data.username,
        }));
      }
    } catch (error) {
      console.error("Error generating username:", error);
    }
  }

  async function addUser() {
    if (validation.passes()) {
      const newUser = new FormData();
      newUser.append("path", userData.path);
      newUser.append("firstname", userData.firstname);
      newUser.append("lastname", userData.lastname);
      newUser.append("email", userData.email);
      newUser.append("phone", userData.phone);
      newUser.append("username", userData.username);
      newUser.append("city", userData.city);
      newUser.append("password", userData.password);
      newUser.append("confirmedPassword", userData.confirmedPassword);
      newUser.append("address", userData.address);
      newUser.append("role", userData.role);
      try {
        await userService.addUser(newUser);
        showAlert({
          title: "Utilisateur ajouté avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAddUser();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur est survenue lors de la création",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserData((prevState) => {
      return {
        ...prevState,
        path: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Modal
        onCancelFunction={CloseModalAddUser}
        onCloseFunction={CloseModalAddUser}
        submitFunction={addUser}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Utilisateurs"
            href="/users"
            activatedLinkTitle="Créer Un utilisateur"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="imageUpload">
                    <Pencil className="edit_avatar_img" />
                  </label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="clock"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </div>
              </div>
              <InputGroup
                classNames="form-control-input-group-add"
                title="Nom"
                type="text"
                name="lastname"
                value={userData.lastname}
                onChangeFunction={handleInputChange}
                onBlurFunction={generateUsername}
              />
              {validationErrors && validationErrors.lastname && (
                <AlertMessages
                  message={validationErrors.lastname}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Prénom"
                type="text"
                name="firstname"
                value={userData.firstname}
                onChangeFunction={handleInputChange}
                onBlurFunction={generateUsername}
              />
              {validationErrors && validationErrors.firstname && (
                <AlertMessages
                  message={validationErrors.firstname}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Téléphone"
                type="tel"
                value={userData.phone}
                onChangeFunction={handleInputChange}
                name="phone"
              />
              {validationErrors && validationErrors.phone && (
                <AlertMessages
                  message={validationErrors.phone}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Email"
                type="email"
                value={userData.email}
                onChangeFunction={handleInputChange}
                name="email"
              />
              {validationErrors && validationErrors.email && (
                <AlertMessages
                  message={validationErrors.email}
                  messageType="error"
                />
              )}
            </div>
            <div className="col-6">
              <InputGroup
                classNames="form-control-input-group-add"
                title="Username"
                type="text"
                value={userData.username}
                name="username"
                readOnly
              />
              {validationErrors && validationErrors.username && (
                <AlertMessages
                  message={validationErrors.username}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Mot de passe"
                type="password"
                value={userData.password}
                onChangeFunction={handleInputChange}
                name="password"
              />
              {validationErrors && validationErrors.password && (
                <AlertMessages
                  message={validationErrors.password}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Confirmer mot de passe"
                type="password"
                value={userData.confirmedPassword}
                onChangeFunction={handleInputChange}
                name="confirmedPassword"
              />
              {validationErrors && validationErrors.confirmedPassword && (
                <AlertMessages
                  message={validationErrors.confirmedPassword}
                  messageType="error"
                />
              )}
              <SelectForm
                onChangeFunction={handleInputChange}
                options={options}
                value={userData.role}
                labelFilter="Role"
                name="role"
                classNames="addUser_select"
                titleSelect="Role"
              />
              {validationErrors && validationErrors.role && (
                <AlertMessages
                  message={validationErrors.role}
                  messageType="error"
                />
              )}
              <SelectForm
                onChangeFunction={handleInputChange}
                disabled={true}
                value={"tunisie"}
                options={["tunisie"]}
                name="country"
                labelFilter="Pays"
                classNames="addUser_select"
                titleSelect="Pays"
              />
              <SelectForm
                onChangeFunction={handleInputChange}
                options={villes}
                name="city"
                value={userData.city}
                labelFilter="Ville"
                classNames="addUser_select"
                titleSelect="Ville"
                MenuProps={MenuProps}
              />
              {validationErrors && validationErrors.city && (
                <AlertMessages
                  message={validationErrors.city}
                  messageType="error"
                />
              )}
              <InputGroup
                classNames="form-control-input-group-add"
                title="Adresse"
                type="text"
                name="address"
                value={userData.address}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.address && (
                <AlertMessages
                  message={validationErrors.address}
                  messageType="error"
                />
              )}
            </div>
          </div>
        }
      />
    </>
  );
}
