import axios from "axios";
import { environment } from "../environments/environment";

export default class CommentService {
  addComment = async (data) => {
    return axios.post(environment.API_URL + "commenatires/coach", data);
  };
  getConversation = async (affectedExerciceId) => {
    return axios.get(environment.API_URL + `commenatires/${affectedExerciceId}`);
  };
}
