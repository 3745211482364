import NavBar from "./navBar";
import SideBar from "./sideBar";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from "../../redux/actions/action";
import showToast from "../lib/Alerts/toast";
import { checkAuth } from "../../services/auth.service";

export default function LayoutSidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const check = async () => {
      checkAuth()
        .then((response) => {
          if (!response.data.isAuthenticated) {
            showToast({
              title: `vous n'êtes pas connecté(e)`,
              iconType: "error",
            });
            return navigate("/login");
          } else {
            dispatch(login(response.data));
            showToast({
              title: `Bienvenue ${response.data.name}`,
              iconType: "success",
            });
            // switch (response.data.role) {
            //   case 'admin':
            //     return navigate("/users");
            //     break;
            //   case 'coach':
            //     return navigate("/adherents");
            //     break;
            //   case 'salle':
            //     return navigate("/subscriptions");
            //     break;
            // }
          }
        })
        .catch((error) => {
          showToast({
            title: `Une erreur est survenue"`,
            iconType: "error",
          });
          console.log(error);
        }).finally(() => {
          setLoading(false)
        });
    };
    check();
  }, [dispatch]);

  return !loading ? <div className="main">
    < NavBar open={open} setOpen={setOpen} />
    <SideBar open={open} setOpen={setOpen} />
    <div className={open ? "partial-navbar" : "full-navbar"}>
      <Outlet />
    </div>
  </div > : ""

}
