import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../lib/tabPanel/TabPanel";
import { useEffect, useState } from "react";
import TableContent from "../../lib/table/table";
import SelectForm from "../../lib/select/select";
import dataNotFound from "../../assets/images/dataNotFound.png";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};
const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
const columns = [
  "Aperçu",
  "Nom",
  "Temps ",
  "Sets",
  "Repétition",
  "Poids",
  "statut",
  "",
];

export default function Planning({
  exercices,
  onTabClickFunction = () => {},
  adherentDays,
  weeks = [1],
  currentWeek = 1,
  setCurrentWeek = () => {},
}) {
  const [value, setValue] = useState(0);
  const [disabledDays, setDisabledDays] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setDisabledDays(days.filter((day) => !adherentDays.includes(day)));
  }, []);
  return (
    <>
      <SelectForm
        onChangeFunction={setCurrentWeek}
        options={weeks}
        value={currentWeek}
        labelFilter="Semaine"
        name="week"
        selectStyle="weeks_select"
        classNames="week_select mb-3"
        MenuProps={MenuProps}
      />
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="transparent"
          aria-label="Vertical tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              height: "0px",
              backgroundColor: "#14CA81",
            },
            "& .MuiTabs-flexContainer": {
              justifyContent: "space-around",
            },
          }}
        >
          {days.map((day, index) => (
            <Tab
              key={index}
              label={day}
              onClick={() => {
                onTabClickFunction(day);
              }}
              {...a11yProps(index)}
              className={`tabs_planning ${
                disabledDays && disabledDays.includes(day) ? "disabled" : ""
              }`}
              disabled={disabledDays?.includes(day)}
            />
          ))}
        </Tabs>
        {days.map((day, index) => (
          <TabPanel key={index} value={value} index={index}>
            {exercices[day] && exercices[day].length > 0 ? (
              <TableContent
                columns={columns}
                data={exercices[day]}
                rowsPerPage={10}
                totalRows={exercices[day].length}
                className="table_affect_adherent"
              />
            ) : (
              <div className="text-center">
                <img className="w-25" src={dataNotFound} />
                <h4 className="">Aucun exercice disponible pour ce jour.</h4>
              </div>
            )}
          </TabPanel>
        ))}
      </Box>
    </>
  );
}
