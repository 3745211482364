import { FormLabel } from "react-bootstrap";
import "../select/select.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
export default function SelectForm({
  options = [""],
  value = "",
  labelFilter = "",
  titleSelect = "",
  classNames = "",
  selectStyle = "",
  name = "",
  MenuProps,
  disabled = false,
  onChangeFunction = () => {},
}) {
  return (
    <>
      <FormLabel>{titleSelect}</FormLabel>
      <FormControl className={classNames}>
        <InputLabel id="labelFilter" className={selectStyle}>
          {labelFilter}
        </InputLabel>
        <Select
          disabled={disabled}
          className={classNames}
          labelId="labelFilter"
          id="simple-select"
          label="role"
          value={value}
          onChange={(e) => {
            onChangeFunction(e.target.value, name);
          }}
          MenuProps={MenuProps}
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#14ca81",
            },
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
