import { useRef, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import "../../assets/css/global.css";
import { Upload } from "react-bootstrap-icons";
import { exerciceCategories } from "../../../utils/variables/categories";
import ExerciceService from "../../../services/exercice.service";
import "../Exercice/addExercice.css";
import TextAreaForm from "../../lib/textArea/textAreaForm";
import Button from "../../lib/button/button";
const validationRules = {
  name: "required|string",
  description: "required|string",
  descriptionAr: "string",
  Image: "required",
  category: "required",
  video: "required",
  ExplicatifImg: "required",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const exerciceService = new ExerciceService();

export default function AddExercice({
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const [userData, setUserData] = useState({
    name: "",
    description: "",
    descriptionAr: "",
    Image: "",
    video: "",
    ExplicatifImg: "",
    category: "",
  });
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});
  const [imageSrc, setImageSrc] = useState("");
  const [explicatifImageSrc, setExplicatifImageSrc] = useState("");
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedDescriptionAr, setSelectedDescriptionAr] = useState(null);
  const [videoSrc, setVideoSrc] = useState("");

  const CloseModalAddExercice = () => {
    closeModalFunction();
  };
  const handleDescriptionClick = (description) => {
    setSelectedDescription(description);
  };
  const handleDescriptionArClick = (description) => {
    setSelectedDescriptionAr(description);
  };
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    name: "nom d'exercice",
    description: "description",
    descriptionAr: "description arabe",
    Image: "image",
    category: "catégorie",
    video: "vidéo",
    ExplicatifImg: "image explicative",
  });

  async function createExercice() {
    if (validation.passes()) {
      const newExercice = new FormData();
      newExercice.append("name", userData.name);
      newExercice.append("description", userData.description);
      newExercice.append("descriptionAr", userData.descriptionAr);
      newExercice.append("Image", userData.Image);
      newExercice.append("video", userData.video);
      newExercice.append("ExplicatifImg", userData.ExplicatifImg);
      newExercice.append("category", userData.category);

      try {
        await exerciceService.addExercice(newExercice);
        showAlert({
          title: "Exercice ajouté avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAddExercice();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur est survenue lors de la création",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }
  const picture__container = useRef(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevState) => ({
      ...prevState,
      Image: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImageSrc("");
    }
  };
  const handleImageExplicatifChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevState) => ({
      ...prevState,
      ExplicatifImg: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setExplicatifImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setExplicatifImageSrc("");
    }
  };
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevState) => ({
      ...prevState,
      video: file,
    }));

    if (file) {
      setVideoSrc(URL.createObjectURL(file));
    } else {
      setVideoSrc("");
    }
  };
  return (
    <>
      <Modal
        onCancelFunction={CloseModalAddExercice}
        onCloseFunction={CloseModalAddExercice}
        submitFunction={createExercice}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Exercices"
            href="/exercices"
            activatedLinkTitle="Créer un nouveau exercice"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <InputGroup
                classNames="form-control-input-group-add mb-3"
                title="Nom d’exercice"
                type="text"
                name="name"
                value={userData.name}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.name && (
                <AlertMessages
                  message={validationErrors.name}
                  messageType="error"
                />
              )}
              <SelectForm
                onChangeFunction={handleInputChange}
                options={exerciceCategories}
                value={userData.category}
                labelFilter="Catégories"
                name="category"
                classNames="addUser_select mb-3 mt-1"
                selectStyle="select_subscription_style"
                titleSelect="Catégories"
                MenuProps={MenuProps}
              />
              {validationErrors && validationErrors.category && (
                <AlertMessages
                  message={validationErrors.category}
                  messageType="error"
                />
              )}
              <div className="d-flex justify-content-between ">
                <span>Description :</span>
                <div>
                  <Button
                    title={"Français"}
                    classNames="button_description me-2"
                    onClickFunction={() => {
                      handleDescriptionClick(userData);
                    }}
                  />
                  <Button
                    title={"Arabe"}
                    classNames="button_description"
                    onClickFunction={() => {
                      handleDescriptionArClick(userData);
                    }}
                  />
                </div>
              </div>
              <div>
                {selectedDescription && (
                  <TextAreaForm
                    rowNum={5}
                    title="Français"
                    name="description"
                    value={userData.description}
                    onChangeFunction={handleInputChange}
                  />
                )}
                {selectedDescriptionAr && (
                  <TextAreaForm
                    rowNum={5}
                    title="Arabe"
                    name="descriptionAr"
                    value={userData.descriptionAr}
                    onChangeFunction={handleInputChange}
                  />
                )}
              </div>
              {validationErrors && validationErrors.description && (
                <AlertMessages
                  message={validationErrors.description}
                  messageType="error"
                />
              )}
              {validationErrors && validationErrors.descriptionAr && (
                <AlertMessages
                  message={validationErrors.descriptionAr}
                  messageType="error"
                />
              )}
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div>
                <h6>Image :</h6>
                <div className="picture__container" ref={picture__container}>
                  <input
                    type="file"
                    id="picture__input"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleImageChange}
                  />
                  <label
                    htmlFor="picture__input"
                    className="d-flex justify-content-center"
                  >
                    {imageSrc ? (
                      <img
                        src={imageSrc}
                        alt="Uploaded Image"
                        className="picture__img"
                      />
                    ) : (
                      <div>
                        <Upload className="picture__image" />
                        <div className="text-nowrap px-5 upload_image_text pt-3">
                          Selectionne image depuis PC
                        </div>
                      </div>
                    )}
                  </label>
                </div>
                {validationErrors && validationErrors.Image && (
                  <AlertMessages
                    message={validationErrors.Image}
                    messageType="error"
                  />
                )}
                <h6 className="pt-3">vidéo 3D :</h6>
                <div
                  className="picture__container mt-2"
                  ref={picture__container}
                >
                  <input
                    type="file"
                    id="video__input"
                    accept=".mp4"
                    onChange={handleVideoChange}
                  />
                  <label
                    htmlFor="video__input"
                    className="d-flex justify-content-center"
                  >
                    {videoSrc ? (
                      <video
                        src={videoSrc}
                        alt="Uploaded Image"
                        className="picture__img"
                      />
                    ) : (
                      <div>
                        <Upload className="picture__image" />
                        <div className="text-nowrap px-5 upload_image_text pt-3">
                          Selectionne image depuis PC
                        </div>
                      </div>
                    )}
                  </label>
                </div>
                {validationErrors && validationErrors.video && (
                  <AlertMessages
                    message={validationErrors.video}
                    messageType="error"
                  />
                )}
                <h6 className="pt-3"> Image Explicative:</h6>
                <div
                  className="picture__container mt-2"
                  ref={picture__container}
                >
                  <input
                    type="file"
                    id="pictureExplicatif__input"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleImageExplicatifChange}
                  />
                  <label
                    htmlFor="pictureExplicatif__input"
                    className="d-flex justify-content-center"
                  >
                    {explicatifImageSrc ? (
                      <img
                        src={explicatifImageSrc}
                        alt="Uploaded Image"
                        className="picture__img"
                      />
                    ) : (
                      <div>
                        <Upload className="picture__image" />
                        <div className="text-nowrap px-5 upload_image_text pt-3">
                          Selectionne image depuis PC
                        </div>
                      </div>
                    )}
                  </label>
                </div>
                {validationErrors && validationErrors.ExplicatifImg && (
                  <AlertMessages
                    message={validationErrors.ExplicatifImg}
                    messageType="error"
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
