import axios from "axios";
import { environment } from "../environments/environment";

export default class SubscriptionPricesService {
  getAllSubscriptionPacks = async () => {
    return axios.get(environment.API_URL + `subscription-prices/packs`);
  };

  changePackInformations = async (period, data) => {
    return axios.put(
      environment.API_URL + `subscription-prices/change/${period}`,
      data
    );
  };

  getSubscriptionPackDetails = async (subscriptionPackId) => {
    return axios.get(
      environment.API_URL + "subscription-prices/details/" + subscriptionPackId
    );
  };
}
