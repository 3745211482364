import axios from "axios";
import { environment } from "../environments/environment";

export default class UserService {
  getAllUsers = async () => {
    return axios.get(environment.API_URL + "users/enabled");
  };
  addUser = async (data) => {
    return axios.post(environment.API_URL + "users/add", data);
  };
  updateUser = async (userId, data) => {
    return axios.put(environment.API_URL + `users/update/${userId}`, data);
  };
  disableUser = async (userId) => {
    return axios.post(environment.API_URL + `users/disable/${userId}`);
  };
  enableUser = async (userId) => {
    return axios.post(environment.API_URL + `users/enable/${userId}`);
  };
  getDisableUsers = async () => {
    return axios.get(environment.API_URL + `users/disabled`);
  };
  generateUserName(data) {
    return axios.post(environment.API_URL + `users/generateUsername`, data);
  }
  getMyInfos() {
    return axios.get(environment.API_URL + `users/me`);
  }
  updateMyInfos(data) {
    return axios.post(environment.API_URL + `users/me`, data);
  }
}
