import { ToggleButton, ToggleButtonGroup } from "@mui/material";
export default function CustomToggleButton({
  options = [],
  value = "",
  name = "",
  onChangeFunction = () => {},
}) {
  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive
        onChange={(e) => {
          onChangeFunction(e.target.value, name);
        }}
        aria-label="Platform"
        sx={{
          "&.MuiToggleButtonGroup-root": {
            display: "flex",
            justifyContent: "center",
          },
          "&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-middleButton": {
            marginLeft: "-2px",
            padding: "7px",
            fontWeight: "500",
            fontSize: "12px !important",
          },
        }}
      >
        {options.map((option, index) => (
          <ToggleButton
            key={index}
            value={option}
            sx={{
              "&.MuiToggleButton-root": {
                margin: "7px",
                backgroundColor: "#F3F3F3",
                color: "#B9B9B9",
                border: "none",
              },
              "&.Mui-selected": {
                backgroundColor: "#14CA81",
                color: "#FFFFFF",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "#14CA81",
                color: "#FFFFFF",
              },
            }}
          >
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}
