import axios from "axios";
import { environment } from "../environments/environment";

export default class ExerciceAdherentService {
  affectExerciceToAdherent = async (data) => {
    return axios.post(environment.API_URL + "exercice-adherent/affect", data);
  };
  getExerciceAffected = async (adherentId, week) => {
    return axios.get(
      environment.API_URL + `adherents/${adherentId}/exercices/${week}`
    );
  };
  updateAffectedExercice = async (affectedExerciceId, data) => {
    return axios.post(
      environment.API_URL + `exercice-adherent/${affectedExerciceId}/update`,
      data
    );
  };
  getAffectedExerciceById = async (affectedExerciceId) => {
    return axios.get(
      environment.API_URL + `exercice-adherent/${affectedExerciceId}/details`
    );
  };
  deleteAffectedExercice = async (affectedExerciceId) => {
    return axios.delete(
      environment.API_URL + `exercice-adherent/${affectedExerciceId}`
    );
  };
}
