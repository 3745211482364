import { CheckCircle, Pencil, PlusCircle, Search } from "react-bootstrap-icons";
import Button from "../lib/button/button";
import SelectForm from "../lib/select/select";
import { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import InputGroup from "../lib/input-group/input-group";
import UserService from "../../services/user.service";
import Validator from "validatorjs";
import AlertMessages from "../lib/Alerts/alert";
import { villes } from "../../utils/variables/villesOptions";
import { environment } from "../../environments/environment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  username: "required",
  city: "required|string",
  address: "required",
};

export default function Settings() {
  const baseUrl = environment.API_URL;
  const userService = new UserService();
  const [infos, setInfos] = useState({});
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});
  const [imageUrl, setImageUrl] = useState();
  useEffect(() => {
    getMyInfos();
  }, []);

  const getMyInfos = async () => {
    try {
      await userService
        .getMyInfos()
        .then((response) => {
          setInfos(response.data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const validation = new Validator(infos, validationRules);
  validation.setAttributeNames({
    firstname: "prénom",
    lastname: "nom",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
    city: "City",
    address: "Adress",
  });

  async function update() {
    if (validation.passes()) {
      const updatedUser = new FormData();
      updatedUser.append("path", infos.path);
      updatedUser.append("firstname", infos.firstname);
      updatedUser.append("lastname", infos.lastname);
      updatedUser.append("email", infos.email);
      updatedUser.append("phone", infos.phone);
      updatedUser.append("username", infos.username);
      updatedUser.append("country", "Tunisie");
      updatedUser.append("city", infos.city);
      updatedUser.append("password", infos.password);
      updatedUser.append("confirm_password", infos.confirm_password);
      updatedUser.append("address", infos.address);
      try {
        await userService.updateMyInfos(updatedUser);
        showAlert({
          title: "mis à jour avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur est survenue lors de la création",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }

  function handleInputChange(value, fields) {
    setInfos((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setInfos((prevState) => {
      return {
        ...prevState,
        path: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (infos._id) {
      setImageUrl(baseUrl + "users/" + infos._id + "/image");
    }
  }, [infos._id]);
  return (
    <>
      <h3 className="pt-2">Paramètres</h3>

      <div className="card_settings me-2">
        <div className="card-body row pt-3">
          <div className="col-12 col-md-5 card_editProfile mx-md-4 pb-3 mx-3 mb-md-2">
            <h4 className="pt-2">Modifier mon profil</h4>
            <div className="card-body">
              <div className="col-12">
                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageChange}
                    />
                    <label htmlFor="imageUpload">
                      <Pencil className="edit_avatar_img" />
                    </label>
                  </div>
                  <div className="avatar-preview">
                    <div
                      id="clock"
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    ></div>
                  </div>
                </div>
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Nom"
                  type="text"
                  name="firstname"
                  value={infos.firstname}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.firstname && (
                  <AlertMessages
                    message={validationErrors.firstname}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Prénom"
                  type="text"
                  name="lastname"
                  value={infos.lastname}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.lastname && (
                  <AlertMessages
                    message={validationErrors.lastname}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Email"
                  type="email"
                  name="email"
                  value={infos.email}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.email && (
                  <AlertMessages
                    message={validationErrors.email}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Téléphone"
                  type="text"
                  name="phone"
                  value={infos.phone}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.phone && (
                  <AlertMessages
                    message={validationErrors.phone}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <SelectForm
                  onChangeFunction={handleInputChange}
                  disabled={true}
                  value={"tunisie"}
                  options={["tunisie"]}
                  name="country"
                  labelFilter="Pays"
                  classNames="addUser_select"
                  titleSelect="Pays"
                />
                {validationErrors && validationErrors.country && (
                  <AlertMessages
                    message={validationErrors.country}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <SelectForm
                  onChangeFunction={handleInputChange}
                  options={villes}
                  name="city"
                  value={infos.city}
                  labelFilter="Ville"
                  classNames="addUser_select"
                  titleSelect="Ville"
                  MenuProps={MenuProps}
                />
                {validationErrors && validationErrors.city && (
                  <AlertMessages
                    message={validationErrors.city}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Adresse"
                  type="text"
                  name="address"
                  value={infos.address}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.address && (
                  <AlertMessages
                    message={validationErrors.address}
                    messageType="error"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 card_editProfile me-md-2 mx-3 mt-2">
            <h4 className="pt-2">Paramètres du compte</h4>
            <div className="card-body">
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Username"
                  type="text"
                  name="username"
                  value={infos.username}
                  disabled={true}
                />
                {validationErrors && validationErrors.username && (
                  <AlertMessages
                    message={validationErrors.username}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Mot de passe"
                  type="text"
                  name="password"
                  value={infos.password}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.password && (
                  <AlertMessages
                    message={validationErrors.password}
                    messageType="error"
                  />
                )}
              </div>
              <div className="col-12">
                <InputGroup
                  classNames="form-control-input-group-add"
                  title="Confirmer mot de passe"
                  type="text"
                  name="confirm_password"
                  value={infos.confirm_password}
                  onChangeFunction={handleInputChange}
                />
                {validationErrors && validationErrors.confirm_password && (
                  <AlertMessages
                    message={validationErrors.confirm_password}
                    messageType="error"
                  />
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  title={"Enregistrer"}
                  color="success"
                  classNames="add_button mt-2 mb-2"
                  onClickFunction={() => {
                    update();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
