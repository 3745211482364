import React, { useState } from "react";
import Input from "../lib/input/input";
import "../assets/css/login.css";
import Card from "react-bootstrap/Card";
import logoDryfit from "../assets/images/logoDryFit.png";
import Button from "../lib/button/button";
import { ArrowClockwise, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import ForgetPasswordService from "../../services/forgetPassword.service";
import NumberCodeInput from "../lib/NumberCodeInput/numberCodeInput";
import InputGroup from "../lib/input-group/input-group";
const forgetPasswordService = new ForgetPasswordService();
export default function ChangePassword() {
  const navigate = useNavigate();

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  function handleInputChange(value, field) {
    setData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }
  async function updatePassword() {
    try {
      await forgetPasswordService.changePassword(data);
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="login-background">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card>
          <Card.Body>
            <div className="text-center">
              <img className="pt-4 " src={logoDryfit} />
            </div>
            <h5 className="py-5 text-center">
              Merci de créer votre nouveau mot de passe.
            </h5>
            <div className="d-flex flex-column align-items-center">
              <InputGroup
                classNames="form-control-input mb-3"
                title="Votre email"
                type="text"
                name="email"
                value={data.email}
                onChangeFunction={handleInputChange}
              />
              <InputGroup
                classNames="form-control-input mb-3"
                title="Votre nouveau mot de passe"
                type="text"
                name="password"
                value={data.password}
                onChangeFunction={handleInputChange}
              />
              <InputGroup
                classNames="form-control-input mb-3"
                title="Confirmer votre nouveau mot de passe"
                type="text"
                name="confirmpassword"
                value={data.confirmpassword}
                onChangeFunction={handleInputChange}
              />
              <Button
                title={"Confirmer"}
                color="success"
                classNames="formButton mt-4"
                onClickFunction={updatePassword}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
