import React, { useState } from "react";
import "./input-group.css";
import { InputGroup as IG, Form } from "react-bootstrap";
export default function InputGroup({
  placeholder = "",
  type = "text",
  title = "",
  name = "",
  classNames = "",
  iconEnd = "",
  input_style = "",
  iconStart = "",
  errorMessage = "",
  value = "",
  disabled = false,
  onChangeFunction = () => {},
  onBlurFunction = () => {},
  onClickFunction = () => {},
}) {
  const [igBorder, setIGBorder] = useState(false);
  return (
      <Form.Group>
        <Form.Label className="fs-6 fw-normal">{title}</Form.Label>
        <IG className={input_style}>
          {iconStart !== "" && (
            <IG.Text
              id="input-group-addon-start"
              className={`${igBorder ? "input-group-addon-border" : ""}`}
            >
              {iconStart}
            </IG.Text>
          )}
          <Form.Control
            required
            disabled={disabled}
            className={`${classNames} `}
            type={type}
            name={name}
            value={value}
            placeholder={`${placeholder}`}
            onChange={(e) => {
              onChangeFunction(e.target.value, name);
            }}
            onFocus={() => {
              setIGBorder(true);
            }}
            onBlur={() => {
              setIGBorder(false);
              onBlurFunction();
            }}
            aria-label={type}
            aria-describedby="input-group-addon"
          />

          {iconEnd !== "" && (
            <IG.Text
              id="input-group-addon-end"
              className={`${igBorder ? "input-group-addon-border" : ""}`}
              onClick={onClickFunction}
            >
              {iconEnd}
            </IG.Text>
          )}
        </IG>
        {errorMessage !== "" && <p className="text-danger">{errorMessage}</p>}
      </Form.Group>
  );
}
