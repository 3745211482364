import axios from "axios";
const API_URL = "https://api-dryfit.msit-demo.fr/";
async function login(data) {
  return axios.post(API_URL + "auth/login", data);
}
async function checkAuth() {
  return axios.get(API_URL + "auth/check");
}
async function logout() {
  return axios.post(API_URL + "auth/logout");
}

export { login, checkAuth, logout };
