import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { fr } from "date-fns/locale";

export default function DatePicker({ onChangeFunction = () => {}, date = [] }) {
  const handleDateChange = (selectedDates) => {
    onChangeFunction([selectedDates.selection]);
  };

  return (
    <>
      <DateRangePicker
        onChange={handleDateChange}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={date}
        locale={fr}
        color={"#14ca81"}
        rangeColors={["#14ca81"]}
        direction="horizontal"
      />
    </>
  );
}
