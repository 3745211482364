import { styled } from "@mui/material";
import { DashLg, PlusLg } from "react-bootstrap-icons";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";
import { useEffect, useState } from "react";

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  border: none;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;
  &:focus {
    border-color: #14ca81;
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? "#14ca81" : "8bfacb"
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: none;
  background:#FFFF;
  color: #14ca81;
  width: 23px;
  height: 23px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? "#14ca81" : "#14ca81"};
    color: #FFFF;
    border-radius:50%
  }
  &:focus-visible {
    outline: 0;
  }
  &.increment {
    order: 1;
  }
`
);

const grey = {
  300: "#C7D0DD",
  500: "#9DA8B7",
  900: "#1C2025",
};

export default function IncrementInput({
  ref,
  props,
  name = "",
  onChangeFunction = () => {},
  value = "",
  minValue,
}) {
  const handleChange = (newValue) => {
    onChangeFunction(newValue, name);
  };
  return (
    <BaseNumberInput
      min={minValue}
      defaultValue={value}
      slots={{
        root: StyledInputRoot,
        input: StyledInput,
        incrementButton: StyledButton,
        decrementButton: StyledButton,
      }}
      onChange={(e) => {
        handleChange(e.target.value);
      }}
      slotProps={{
        incrementButton: {
          children: <PlusLg fontSize="small" />,
          className: "increment",
        },
        decrementButton: {
          children: <DashLg fontSize="small" />,
        },
      }}
      {...props}
      ref={ref}
    />
  );
}
