import Modal from "../modal/modal";
import "../../assets/css/global.css";
import "../Exercice/addExercice.css";
import "../exerciceAdherent/affectExercice.css";
import { environment } from "../../../environments/environment";

export default function VideoPreview({
  closeModalFunction,
  exerciceId,
}) {
  const CloseModalAffectExercice = () => {
    closeModalFunction();
  };
  const baseUrl = environment.API_URL;
  return (
    <>
      <Modal
        onCancelFunction={CloseModalAffectExercice}
        onCloseFunction={CloseModalAffectExercice}
        cancelText="retour"
        isOpened={true}
        dialogContentChild={
          <video
            src={baseUrl + "exercices/" + exerciceId + "/video"}
            alt="Uploaded Video"
            className="w-100 video_preview"
            controls
          />
        }
      />
    </>
  );
}
