import React, { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import LogService from "../../services/log.service";

export default function Logs() {
  const logService = new LogService();
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getLogs();
  }, []);

  const getLogs = async () => {
    try {
      await logService
        .getAllLogs()
        .then((response) => {
          const data = response.data;
          setLogs(data);
        })
        .catch((error) => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const day = date.getDate().toString().padStart(2, "0"); // Get day with zero-padding
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month with zero-padding (months are 0-indexed)
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}/${month}/${year} à ${hours}:${minutes}`;
  }

  return (
    <div className="col-6 container ms-0 vh-100">
      <div className="me-2">
        <div className="fw-bolder fs-4 pt-3">
          <a href="/users" className="text-decoration-none text-black">
            Connexions
          </a>
        </div>
        <div className="card_notifications mt-3 ps-3">
          <div className="card-body">
            <h6 className="pt-3">
              <strong >Historiques</strong>
            </h6>
            <div className="mt-3">
              {logs &&
                logs.map((log, key) => {
                  return (
                    <div className="" key={key}>
                      <span className="log-username">{log.username}</span> à{" "}
                      {log.type === "login" ? "connecté" : "déconnecté"} le{" "}
                      {formatDate(log.createdAt)}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
