import axios from "axios";
import { environment } from "../environments/environment";

export default class CoachService {
  getAllCoachs = async () => {
    return axios.get(environment.API_URL + "coachs/enabled");
  };
  addCoach = async (data) => {
    return axios.post(environment.API_URL + "coachs/add", data);
  };
  updateStatus = async (coachId, data) => {
    return axios.post(
      environment.API_URL + `coachs/updateStatus/${coachId}`,
      data
    );
  };
}
