import axios from "axios";
import { environment } from "../environments/environment";

export default class LogService {
  getAllLogs = async () => {
    return axios.get(environment.API_URL + "log");
  };

  getAllNotifications = async () => {
    return axios.get(environment.API_URL + "log/notifications");
  };
}
