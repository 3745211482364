import React, { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import LogService from "../../services/log.service";

export default function Notifications() {
  const logService = new LogService();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    try {
      await logService
        .getAllNotifications()
        .then((response) => {
          const data = response.data;
          setNotifications(data);
        })
        .catch((error) => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  function formatHour(dateString) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }
  function formatDate(dateString) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const day = date.getDate().toString().padStart(2, "0"); // Get day with zero-padding
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month with zero-padding (months are 0-indexed)
    const year = date.getFullYear();

    return `${day}/${month}/${year} `;
  }
  return (
    <div className="col-6 container ms-0 vh-100">
      <div className="me-2">
        <div className="fw-bolder fs-4 pt-3">
          <a href="/users" className="text-decoration-none text-black ">
            Notifications({notifications.length})
          </a>
        </div>

        <div className="card_notifications text-center mt-3">
          <div className="card-body">
            <div className="mt-3">
              {notifications.map((log, key) => (
                <div className="d-flex justify-content-between" key={key}>
                  <div className="ps-2 date_notif">
                    {formatDate(log.createdAt)}
                  </div>
                  <span className="log-username">{log.message}</span>
                  <div className="pe-3 date_notif">
                    {formatHour(log.createdAt)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
