import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./table.css";
import { Pagination, TableFooter } from "@mui/material";

export default function TableContent({
  columns,
  data,
  rowsPerPage,
  totalRows,
}) {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(Math.ceil(totalRows / rowsPerPage));
  }, [rowsPerPage, totalRows]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <TableContainer component={Paper} className="mt-3 table_container ">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, key) => (
              <TableCell key={key} className="table_header">
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
            .map((item, key) => (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {item.map((element, index) => (
                  <TableCell key={index} className="table_data text-nowrap">
                    {element}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
        <TableFooter className="border-top">
          <Pagination
            className="custom-pagination py-3  "
            count={totalPages}
            page={page}
            onChange={handleChange}
            variant="outlined"
            shape="rounded"
            sx={{
              ".MuiPagination-ul": {
                display: "flex",
                justifyContent: "end",
                flexWrap: "nowrap",
              },
            }}
          />
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
