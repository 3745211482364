import { useRef, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";

import { environment } from "../../../environments/environment";
import { exerciceCategories } from "../../../utils/variables/categories";
import TextAreaForm from "../../lib/textArea/textAreaForm";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const baseUrl = environment.API_URL;

export default function DetailsExercice({ exercice = {}, closeModalFunction }) {
  const [imageSrc, setImageSrc] = useState(
    baseUrl + "exercices/" + exercice._id + "/SimpleImage"
  );
  const [explicatifImageSrc, setExplicatifImageSrc] = useState(
    baseUrl + "exercices/" + exercice._id + "/ExplicatifImage"
  );
  const [videoSrc, setVideoSrc] = useState(
    baseUrl + "exercices/" + exercice._id + "/video"
  );

  const picture__container = useRef(null);

  const CloseModalDetailsExercice = () => {
    closeModalFunction();
  };
  return (
    <>
      <Modal
        onCancelFunction={CloseModalDetailsExercice}
        onCloseFunction={CloseModalDetailsExercice}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Exercices"
            href="/exercices"
            activatedLinkTitle="Details d'un exercice"
            color="titleModal"
          />
        }
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <InputGroup
                classNames="form-control-input-group-add mb-3"
                title="Nom d’exercice"
                type="text"
                name="name"
                disabled={true}
                value={exercice.name}
              />
              <SelectForm
                options={exerciceCategories}
                value={exercice.category}
                // labelFilter="Catégories"
                name="category"
                classNames="addUser_select mb-3 "
                titleSelect="Catégories"
                MenuProps={MenuProps}
                disabled={true}
              />

              <TextAreaForm
                rowNum={5}
                title="Description Français"
                name="description"
                disabled={true}
                value={exercice.description}
              />

              <TextAreaForm
                rowNum={5}
                title="Description Arabe"
                name="descriptionAr"
                disabled={true}
                value={exercice.descriptionAr}
              />
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div>
                <h6>Image :</h6>
                <div className="picture__container" ref={picture__container}>
                  <label htmlFor="picture__input">
                    <img
                      src={imageSrc}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>

                <h6 className="pt-3">vidéo 3D :</h6>
                <div
                  className="picture__container mt-2"
                  ref={picture__container}
                >
                  <label htmlFor="video__input">
                    <video
                      src={videoSrc}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>

                <h6 className="pt-3"> Image Explicative:</h6>
                <div
                  className="picture__container mt-2"
                  ref={picture__container}
                >
                  <label htmlFor="pictureExplicatif__input">
                    <img
                      src={explicatifImageSrc}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
