import { useRef, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import { environment } from "../../../environments/environment";
import ExerciceService from "../../../services/exercice.service";
import { exerciceCategories } from "../../../utils/variables/categories";
import TextAreaForm from "../../lib/textArea/textAreaForm";
const validationRules = {
  name: "required|string",
  description: "required|string",
  descriptionAr: "string",
  Image: "required",
  category: "required",
  video: "required",
  ExplicatifImg: "required",
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const exerciceService = new ExerciceService();
const baseUrl = environment.API_URL;

export default function EditExercice({
  exercice = {},
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const [userData, setUserData] = useState(exercice);
  const [validationErrors, setValidationErrors] = useState({});
  const [imageSrc, setImageSrc] = useState(
    baseUrl + "exercices/" + exercice._id + "/SimpleImage"
  );
  const [explicatifImageSrc, setExplicatifImageSrc] = useState(
    baseUrl + "exercices/" + exercice._id + "/ExplicatifImage"
  );
  const [videoSrc, setVideoSrc] = useState(
    baseUrl + "exercices/" + exercice._id + "/video"
  );
  Validator.useLang("fr");

  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }
  const picture__container = useRef(null);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevState) => ({
      ...prevState,
      Image: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setImageSrc("");
    }
  };
  const handleImageExplicatifChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevState) => ({
      ...prevState,
      ExplicatifImg: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setExplicatifImageSrc(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      setExplicatifImageSrc("");
    }
  };
  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setUserData((prevState) => ({
      ...prevState,
      video: file,
    }));

    if (file) {
      setVideoSrc(URL.createObjectURL(file));
    } else {
      setVideoSrc("");
    }
  };
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    name: "nom d'exercice",
    description: "description",
    descriptionAr: "description arabe",
    Image: "image",
    category: "catégorie",
    video: "vidéo",
    ExplicatifImg: "image explicative",
  });
  const CloseModalEditExercice = () => {
    closeModalFunction();
  };

  async function updateExercice() {
    if (validation.passes()) {
      const updatedExercice = new FormData();
      updatedExercice.append("name", userData.name);
      updatedExercice.append("description", userData.description);
      updatedExercice.append("descriptionAr", userData.descriptionAr);
      updatedExercice.append("Image", userData.Image);
      updatedExercice.append("video", userData.video);
      updatedExercice.append("ExplicatifImg", userData.ExplicatifImg);
      updatedExercice.append("category", userData.category);
      try {
        await exerciceService.editExercice(exercice._id, updatedExercice);
        showAlert({
          title: "Exercice modifié avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalEditExercice();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur de modification est survenue",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }

  return (
    <>
      <Modal
        onCancelFunction={CloseModalEditExercice}
        onCloseFunction={CloseModalEditExercice}
        submitFunction={updateExercice}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Exercices"
            href="/exercices"
            activatedLinkTitle="Modifier un exercice"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <InputGroup
                classNames="form-control-input-group-add mb-3"
                title="Nom d’exercice"
                type="text"
                name="name"
                value={userData.name}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.name && (
                <AlertMessages
                  message={validationErrors.name}
                  messageType="error"
                />
              )}
              <SelectForm
                onChangeFunction={handleInputChange}
                options={exerciceCategories}
                value={userData.category}
                name="category"
                classNames="addUser_select mb-3"
                titleSelect="Catégories"
                MenuProps={MenuProps}
              />
              {validationErrors && validationErrors.category && (
                <AlertMessages
                  message={validationErrors.category}
                  messageType="error"
                />
              )}
              <TextAreaForm
                rowNum={5}
                title="Description Français"
                name="description"
                value={userData.description}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.description && (
                <AlertMessages
                  message={validationErrors.description}
                  messageType="error"
                />
              )}
              <TextAreaForm
                rowNum={5}
                title="Description Arabe"
                name="descriptionAr"
                value={userData.descriptionAr}
                onChangeFunction={handleInputChange}
              />
              {validationErrors && validationErrors.descriptionAr && (
                <AlertMessages
                  message={validationErrors.descriptionAr}
                  messageType="error"
                />
              )}
            </div>
            <div className="col-6 d-flex justify-content-center">
              <div>
                <h6>Image :</h6>
                <div className="picture__container" ref={picture__container}>
                  <input
                    type="file"
                    id="picture__input"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleImageChange}
                  />
                  <label htmlFor="picture__input">
                    <img
                      src={imageSrc}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>
                {validationErrors && validationErrors.Image && (
                  <AlertMessages
                    message={validationErrors.Image}
                    messageType="error"
                  />
                )}
                <h6 className="pt-3">vidéo 3D :</h6>
                <div
                  className="picture__container mt-2"
                  ref={picture__container}
                >
                  <input
                    type="file"
                    id="video__input"
                    accept=".mp4"
                    onChange={handleVideoChange}
                  />
                  <label htmlFor="video__input">
                    <video
                      src={videoSrc}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>
                {validationErrors && validationErrors.video && (
                  <AlertMessages
                    message={validationErrors.video}
                    messageType="error"
                  />
                )}
                <h6 className="pt-3"> Image Explicative:</h6>
                <div
                  className="picture__container mt-2"
                  ref={picture__container}
                >
                  <input
                    type="file"
                    id="pictureExplicatif__input"
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleImageExplicatifChange}
                  />
                  <label htmlFor="pictureExplicatif__input">
                    <img
                      src={explicatifImageSrc}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>
                {validationErrors && validationErrors.ExplicatifImg && (
                  <AlertMessages
                    message={validationErrors.ExplicatifImg}
                    messageType="error"
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
