import { CheckCircle, PlusCircle, Search } from "react-bootstrap-icons";
import Button from "../lib/button/button";
import SelectForm from "../lib/select/select";
import TableContent from "../lib/table/table";
import { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import InputGroup from "../lib/input-group/input-group";
import { statusOptions } from "../../utils/variables/statusOptions";
import { villes } from "../../utils/variables/villesOptions";
import { useNavigate } from "react-router-dom";
import SubscriptionService from "../../services/subscription.service";
import AddSubscription from "../components/subscription/addSubscription";
import dataNotFound from "../assets/images/dataNotFound.png";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

const columns = ["Code", "Nom", "Email", "Téléphone", "Date", "Abonnement"];

export default function Subscriptions() {
  const subscriptionService = new SubscriptionService();

  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    city: "",
    status: "",
    state: "",
    search: "",
  });

  const [openModal, setOpenModal] = useState("");
  useEffect(() => {
    getAllSubscriptions();
  }, []);

  useEffect(() => {
    filter();
  }, [filterOptions]);
  const filter = () => {
    let filtredData = subscriptions;
    if (filterOptions.status !== "") {
      filtredData = filtredData.filter(
        (salle) => salle[2].props.children === filterOptions.status
      );
    }
    if (filterOptions.city !== "") {
      filtredData = filtredData.filter(
        (salle) => salle[1] === filterOptions.city
      );
    }
    if (filterOptions.search !== "") {
      filtredData = filtredData.filter((salle) => {
        return (
          salle &&
          JSON.stringify(salle)
            .toLowerCase()
            .includes(filterOptions.search.toLowerCase())
        );
      });
    }
    setSubscriptions(filtredData);
  };

  function search(searchQuery) {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        search: searchQuery,
      };
    });
  }

  const getAllSubscriptions = async () => {
    try {
      await subscriptionService
        .getAll()
        .then((response) => {
          const data = response.data.map((salle) =>
            formatSubscriptionData(salle)
          );
          setSubscriptions(data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const formatSubscriptionData = (subscription) => {
    return [
      subscription.code,
      subscription.firstname + " " + subscription.lastname,
      subscription.phone,
      subscription.email,
      subscription.createdAt.slice(0, 10),
      subscription.period,
    ];
  };

  const filteringStatus = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        status: value,
      };
    });
  };
  const filteringCity = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        city: value,
      };
    });
  };
  const closeModal = () => {
    setOpenModal("");
  };

  const resetFilters = () => {
    setFilterOptions({
      city: "",
      status: "",
      state: "",
      search: "",
    });
  };

  return (
    <div className="me-2">
      <div className="fw-bolder fs-4 pt-3">
        <a href="/agents" className="text-decoration-none text-black">
          Abonnements
        </a>
      </div>
      <div>
        <InputGroup
          classNames="form-control-input-group-search "
          type={"text"}
          placeholder="Recherche"
          iconStart={<Search />}
          input_style="inputStyleSearch"
          value={filterOptions.search}
          onChangeFunction={search}
        />
      </div>
      <div className="d-flex align-items-center mt-3">
        <div className="d-flex justify-content-start align-items-center pe-5">
          <SelectForm
            onChangeFunction={filteringCity}
            options={villes}
            MenuProps={MenuProps}
            value={filterOptions.city}
            labelFilter="région"
            classNames="filtered_select me-3"
            selectStyle="label_select_status"
          />
          <SelectForm
            onChangeFunction={filteringStatus}
            options={statusOptions}
            value={filterOptions.status}
            labelFilter="Statuts"
            classNames="filtered_select "
            selectStyle="label_select_status"
          />
        </div>
        <div>
          <Button
            title={"Effacer filtres"}
            color="secondary"
            classNames="clearFilterButton p-0"
            onClickFunction={resetFilters}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <Button
          title={"Nouvel abonnement"}
          color="success"
          classNames="add_button_abonnements"
          iconStart={<PlusCircle className="icon_add" />}
          onClickFunction={() => {
            setOpenModal(
              <AddSubscription
                closeModalFunction={closeModal}
                onSuccessFunction={getAllSubscriptions}
              />
            );
          }}
        />
      </div>
      {subscriptions.length > 0 ? (
        <TableContent
          columns={columns}
          data={subscriptions}
          rowsPerPage={5}
          totalRows={subscriptions.length}
        />
      ) : (
        <div className="text-center">
          <img className="w-25" src={dataNotFound} />
          <h4 className="">Aucun abonnement trouvé.</h4>
        </div>
      )}
      {openModal}
    </div>
  );
}
