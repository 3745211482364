import React from "react";
import "./input.css";
import Form from "react-bootstrap/Form";
export default function Input({
  placeholder = "",
  type = "text",
  title = "",
  classNames = "",
  errorMessage = "",
  onChangeFunction = () => {},
}) {
  return (
    <>
      <Form.Group>
        <Form.Label className="fs-6 fw-normal">{title}</Form.Label>
        <Form.Control
          required
          className={`${classNames} `}
          type={`${type}`}
          placeholder={`${placeholder}`}
          onChange={(e) => {
            onChangeFunction(e.target.value);
          }}
        />
        {errorMessage !== "" && <p className="text-danger">{errorMessage}</p>}
      </Form.Group>
    </>
  );
}
