import { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import { Pencil, PlusCircle } from "react-bootstrap-icons";
import { environment } from "../../../environments/environment";
import AdherentService from "../../../services/adherent.service";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../lib/tabPanel/TabPanel";
import Button from "../../lib/button/button";
import { useNavigate } from "react-router-dom";
import ExerciceAdherentService from "../../../services/exerciceAdherent.service";
import Planning from "./planning";
import { useSelector } from "react-redux";
import AdherentSubscriptionService from "../../../services/adherentSubscription.service";

const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  username: "required",
};

const adherentService = new AdherentService();
const exerciceAdherentService = new ExerciceAdherentService();
const adherentSubscriptionService = new AdherentSubscriptionService();

const baseUrl = environment.API_URL;

export default function EditAdherent({
  adherent = {},
  closeModalFunction,
  onSuccessFunction = () => {},
  remainingDays,
}) {
  const connectedUser = useSelector((store) => store.user.user);

  const navigate = useNavigate();
  const [userData, setUserData] = useState(adherent);
  const [validationErrors, setValidationErrors] = useState({});
  const [value, setValue] = useState(0);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(1);
  const [userDetails, setUserDetails] = useState();
  const [adherentData, setAdherentData] = useState();
  const [coachData, setCoachData] = useState({});

  const [exercices, setExercices] = useState({});

  const [imageUrl, setImageUrl] = useState(
    baseUrl + "adherents/" + adherent._id + "/image"
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  Validator.useLang("fr");

  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserData((prevState) => {
      return {
        ...prevState,
        avatar: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    firstname: "prénom",
    lastname: "nom",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
  });
  const CloseModalEditAdherent = () => {
    closeModalFunction();
  };

  async function editAdherent() {
    if (validation.passes()) {
      const updatedAdherent = new FormData();
      updatedAdherent.append("firstname", userData.firstname);
      updatedAdherent.append("lastname", userData.lastname);
      updatedAdherent.append("email", userData.email);
      updatedAdherent.append("phone", userData.phone);
      updatedAdherent.append("username", userData.username);
      updatedAdherent.append("avatar", userData.avatar);
      try {
        await adherentService.updateAdherent(adherent._id, updatedAdherent);
        showAlert({
          title: "Adhérent modifié avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalEditAdherent();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur de modification est survenue",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const getQuestionnaire = async () => {
    try {
      await adherentService.getResponses(adherent._id).then((response) => {
        const data = response.data;
        setQuestionnaire(data);
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const getInformationsAdherent = async () => {
    try {
      await adherentService
        .getAdherentInformation(adherent._id)
        .then((response) => {
          const data = response.data;
          setUserDetails(data);
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const getDetailsAdherent = async () => {
    try {
      await adherentService
        .getAdherentDetails(adherent._id)
        .then((response) => {
          const data = response.data;
          data.dayEnum = data.days.map((day) => {
            return day === "monday"
              ? "Lun"
              : day === "tuesday"
              ? "Mar"
              : day === "wednesday"
              ? "Mer"
              : day === "thursday"
              ? "Jeu"
              : day === "friday"
              ? "Ven"
              : day === "saturday"
              ? "Sam"
              : day === "sunday"
              ? "Dim"
              : "";
          });
          setAdherentData(data);
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const getAffectedExercices = async () => {
    try {
      const response = await exerciceAdherentService.getExerciceAffected(
        adherent._id,
        currentWeek
      );

      const formattedData = {};
      for (const key in response.data.exercices) {
        if (Object.hasOwnProperty.call(response.data.exercices, key)) {
          const exercice = response.data.exercices[key];
          if (!formattedData[key]) {
            formattedData[key] = [];
          }
          const formattedExercices = [];
          for (const index in exercice) {
            if (Object.hasOwnProperty.call(exercice, index)) {
              const item = exercice[index];
              const statusTextClass =
                item.isDone === "no"
                  ? "Non terminé "
                  : item.isDone === "yes"
                  ? "Terminé"
                  : "";
              const StatusStyleClass =
                item.isDone === "no"
                  ? "status_non_completed"
                  : item.isDone === "yes"
                  ? "status_done"
                  : "";
              formattedExercices.push([
                <img
                  src={
                    baseUrl +
                    "exercices/" +
                    item.exercice._id +
                    "/ExplicatifImage"
                  }
                  className="affect_adherent_img"
                />,
                item.exercice.name,
                item.time !== "00:00" ? item.time : "-",
                item.sets,
                item.repeat > 0 ? item.repeat : "-",
                item?.weight > 0 ? `à partir de ${item?.weight} Kg` : "-",
                <div className={StatusStyleClass}>{statusTextClass}</div>,
              ]);
            }
          }
          formattedData[key] = formattedExercices;
        }
        setExercices(formattedData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleToEditPlanning = () => {
    return navigate(`/planning/${adherent._id}`);
  };
  const getCoachAffected = async () => {
    try {
      await adherentService
        .getCoachsByAdherent(adherent._id)
        .then((response) => {
          setCoachData(response.data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const formatDate = () => {
    const formattedDate = new Date(userData.createdAt).toLocaleDateString(
      "en-GB"
    );
    return formattedDate;
  };

  const getWeeksOfSubscription = async () => {
    try {
      const response = await adherentSubscriptionService.getSubscriptionWeeks(
        adherent._id
      );
      const allWeeks = [];
      for (let week = 1; week <= response.data.weeks; week++) {
        allWeeks.push(week);
      }
      setWeeks(allWeeks);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAffectedExercices();
  }, [currentWeek]);

  useEffect(() => {
    getQuestionnaire();
    getInformationsAdherent();
    getDetailsAdherent();
    getCoachAffected();
    getWeeksOfSubscription();
  }, []);
  return (
    <Modal
      onCancelFunction={CloseModalEditAdherent}
      onCloseFunction={CloseModalEditAdherent}
      submitFunction={editAdherent}
      cancelText="annuler"
      dialogTitle={
        <BreadcrumbsLink
          firstLinkTitle="Adhérents"
          href="/adherents"
          activatedLinkTitle={"Modifier l'adhérent " + adherent.username}
          color="titleModal"
        />
      }
      applyTitle="Sauvgarder"
      isOpened={true}
      dialogContentChild={
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Vertical tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                height: "7px",
                backgroundColor: "#14CA81",
              },
              "& .Mui-selected": {
                color: "black !important",
                fontSize: "14px",
                fontWeight: "600",
                width: "100%",
              },
              "& .MuiTabs-flexContainer": {
                justifyContent: "space-between",
              },
            }}
          >
            {connectedUser?.role !== "coach" && (
              <Tab label="Informations générales" {...a11yProps(0)} />
            )}
            <Tab label={`Informations d’adhérant`} {...a11yProps(1)} />
            {adherentData?.days ? (
              <Tab label={`Planning`} {...a11yProps(2)} />
            ) : (
              ""
            )}
          </Tabs>
          {connectedUser?.role !== "coach" && (
            <TabPanel value={value} index={0}>
              <div className="row">
                <div className="col-6">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="imageUpload">
                        <Pencil className="edit_avatar_img" />
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="clock"
                        style={{ backgroundImage: `url(${imageUrl})` }}
                      ></div>
                    </div>
                  </div>
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Nom"
                    type="text"
                    name="lastname"
                    value={userData.lastname}
                    onChangeFunction={handleInputChange}
                  />
                  {validationErrors && validationErrors.lastname && (
                    <AlertMessages
                      message={validationErrors.lastname}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Prénom"
                    type="text"
                    name="firstname"
                    value={userData.firstname}
                    onChangeFunction={handleInputChange}
                  />
                  {validationErrors && validationErrors.firstname && (
                    <AlertMessages
                      message={validationErrors.firstname}
                      messageType="error"
                    />
                  )}
                </div>
                <div className="col-6">
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Téléphone"
                    type="tel"
                    value={userData.phone}
                    onChangeFunction={handleInputChange}
                    name="phone"
                  />
                  {validationErrors && validationErrors.phone && (
                    <AlertMessages
                      message={validationErrors.phone}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Email"
                    type="email"
                    value={userData.email}
                    onChangeFunction={handleInputChange}
                    name="email"
                  />
                  {validationErrors && validationErrors.email && (
                    <AlertMessages
                      message={validationErrors.email}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Username"
                    type="text"
                    value={userData.username}
                    onChangeFunction={handleInputChange}
                    name="username"
                  />
                  {validationErrors && validationErrors.username && (
                    <AlertMessages
                      message={validationErrors.username}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Ajouté le"
                    type="text"
                    value={formatDate(userData.createdAt)}
                    onChangeFunction={handleInputChange}
                    name="createdAt"
                  />
                  {validationErrors && validationErrors.createdAt && (
                    <AlertMessages
                      message={validationErrors.createdAt}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    disabled={true}
                    onChangeFunction={handleInputChange}
                    name="period"
                    value={remainingDays ? `${remainingDays} jours` : "---"}
                    labelFilter="Abonnement"
                    classNames="form-control-input-group-add"
                    title="période d'abonnement restantes"
                  />
                </div>
              </div>
            </TabPanel>
          )}
          <TabPanel
            value={value}
            index={connectedUser?.role !== "coach" ? 1 : 0}
          >
            <div>
              <div className="row mb-3 border-bottom">
                <div className="col-sm-6 col-md-4 col-lg-4 ">
                  <div className="row my-2 field-label">
                    <div className="col-auto">Nom :</div>
                    <div className="col-auto">
                      <div className="d-inline-flex">
                        <strong>
                          {userData?.firstname || "non renseigné"}
                        </strong>
                      </div>
                    </div>
                  </div>

                  <div className="row my-2 field-label">
                    <div className="col-auto">Pays :</div>
                    <div className="col">
                      <div className="d-inline-flex ">
                        <strong>{userData?.country || "non renseigné"}</strong>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2 field-label">
                    <div className="col-auto">Ville :</div>
                    <div className="col ">
                      <div className="d-inline-flex">
                        <strong>{userData?.city || "non renseigné"}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4  ">
                  <div className="row my-2 field-label">
                    <div className="col-auto">Affecté à : </div>
                    <div className="col">
                      <div className="d-inline-flex  px-2 coachAffected">
                        <strong>
                          {coachData[0]?.coach?.username || "non renseigné"}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2 field-label">
                    <div className="col-auto">Poid :</div>
                    <div className="col-6">
                      <div className="d-inline-flex">
                        <strong>
                          {adherentData?.weight
                            ? adherentData.weight + " kg"
                            : "non renseigné"}
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2 field-label">
                    <div className="col-auto ">Longeur :</div>
                    <div className="col  ">
                      <div className="d-inline-flex">
                        <strong>
                          {adherentData?.height
                            ? adherentData?.height + " cm"
                            : "non renseigné"}{" "}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4  ">
                  <div className="row my-2 field-label">
                    <div className="col-auto ">Valabilité d’abonnement:</div>
                    <div className="col  ">
                      <div className="d-inline-flex">
                        <strong>90 Jours</strong>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2 field-label">
                    <div className="col-auto">Age :</div>
                    <div className="col  ">
                      <div className="d-inline-flex ">
                        <strong>
                          {adherentData?.age || "non renseigné"} ans
                        </strong>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 mb-1 field-label">
                    <div className="col-auto">Jours par semaine :</div>
                  </div>
                  <div className="row mb-2 field-label">
                    <div className="col-auto text-muted d-flex justify-between">
                      {adherentData?.days
                        ? adherentData?.dayEnum + ","
                        : "non renseigné"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-3 p-2">
                  <div className="row my-2 field-label">
                    <div className="col-auto ">Objectif :</div>
                    <div className="col-auto ">
                      <div className="d-inline-flex px-2">
                        <strong>
                          {adherentData?.goal === "gain_weight"
                            ? "gagner du poids"
                            : adherentData?.goal === "lose_weight"
                            ? "perdre du poids"
                            : adherentData?.goal === "get_fitter"
                            ? "devenir plus en forme"
                            : adherentData?.goal === "gain_more_flexible"
                            ? "devenir plus souple et flexible"
                            : adherentData?.goal === "learn_basics"
                            ? "apprendre les bases"
                            : ""}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 p-2">
                  <div className="row my-2 field-label">
                    <div className="col-auto ">Genre :</div>
                    <div className="col-auto ">
                      <div className="d-inline-flex px-2">
                        <strong>
                          {adherentData?.sexe === "female" ? "femme" : "homme"}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                {questionnaire.map((reponse, key) => {
                  return (
                    <div className="col-sm-6 col-md-4 col-lg-3 p-2" key={key}>
                      <div className="row my-2 field-label ">
                        <div className="col-auto question_adherent">
                          {reponse?.question}
                        </div>
                        <div className="col-auto ">
                          <div className="d-inline-flex px-2">
                            <strong>{reponse?.text}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </TabPanel>
          {adherentData?.days ? (
            <TabPanel
              value={value}
              index={connectedUser?.role !== "coach" ? 2 : 1}
            >
              <div className="pb-2 fw-bolder fs-6 text-black"></div>
              <Planning
                exercices={exercices}
                adherentDays={adherentData?.days ? adherentData?.days : []}
                weeks={weeks}
                currentWeek={currentWeek}
                setCurrentWeek={setCurrentWeek}
              />
              <div className="d-flex justify-content-center">
                <Button
                  title={
                    exercices && Object.keys(exercices).length > 0
                      ? "Modifier le planning"
                      : "Ajouter un planning"
                  }
                  color="success"
                  classNames="add_planning mt-3"
                  onClickFunction={toggleToEditPlanning}
                />
              </div>
            </TabPanel>
          ) : (
            ""
          )}
        </Box>
      }
    />
  );
}
