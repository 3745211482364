import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../views/pages/login";
import Users from "../views/pages/users";

import LayoutSidebar from "../views/layout/layout";
import DisableUsers from "../views/pages/disableUsers";
import Coachs from "../views/pages/coachs";
import Salles from "../views/pages/salles";
import Adherents from "../views/pages/adherents";
import Exercices from "../views/pages/exercices";
import AdherentPlanning from "../views/pages/adherentPlanning";
import Logs from "../views/pages/logs";
import Notifications from "../views/pages/notifications";
import Subscriptions from "../views/pages/subscriptions";
import Settings from "../views/pages/settings";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import ForgetPassword from "../views/pages/forgetPassword";
import VerifyCode from "../views/pages/verifyCode";
import ChangePassword from "../views/pages/changePassword";
import Packs from "../views/pages/packs";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const navigate = useNavigate();
  const role = useSelector((state) => state.user.user.role);
  const [allowed, setAllowed] = useState(false);
  useEffect(() => {
    if (!role || !allowedRoles.includes(role)) {
      navigate("/");
    }
    setAllowed(true);
  }, []);
  return allowed ? children : "";
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword />,
  },
  {
    path: "/codeVerify",
    element: <VerifyCode />,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
  },
  {
    path: "/",
    element: <LayoutSidebar />,
    children: [
      {
        path: "users",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur"]}>
            <Users />
          </ProtectedRoute>
        ),
      },
      {
        path: "logs",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur"]}>
            <Logs />
          </ProtectedRoute>
        ),
      },
      {
        path: "notifications",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur", "coach"]}>
            <Notifications />
          </ProtectedRoute>
        ),
      },
      {
        path: "archive",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur"]}>
            <DisableUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "coachs",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur"]}>
            <Coachs />
          </ProtectedRoute>
        ),
      },
      {
        path: "agents",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur"]}>
            <Salles />
          </ProtectedRoute>
        ),
      },
      {
        path: "packs",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur"]}>
            <Packs />
          </ProtectedRoute>
        ),
      },
      {
        path: "adherents",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur", "coach"]}>
            <Adherents />
          </ProtectedRoute>
        ),
      },
      {
        path: "exercices",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur", "coach"]}>
            <Exercices />
          </ProtectedRoute>
        ),
      },
      {
        path: "planning/:adherentId",
        element: (
          <ProtectedRoute allowedRoles={["admin", "editeur", "coach"]}>
            <AdherentPlanning />
          </ProtectedRoute>
        ),
      },
      {
        path: "subscriptions",
        element: (
          <ProtectedRoute allowedRoles={["agent"]}>
            <Subscriptions />
          </ProtectedRoute>
        ),
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function NotFoundPage() {
  return <h1>404 - Page Not Found</h1>;
}

export default router;
