import { useEffect, useRef, useState } from "react";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import "../../assets/css/global.css";
import "../Exercice/addExercice.css";
import "../exerciceAdherent/affectExercice.css";
import IncrementInput from "../../lib/incrementInput/numberIncrementInput";
import TextAreaForm from "../../lib/textArea/textAreaForm";
import { environment } from "../../../environments/environment";
import ExerciceAdherentService from "../../../services/exerciceAdherent.service";
import InputGroup from "../../lib/input-group/input-group";
import Validator from "validatorjs";
import showAlert from "../../lib/Alerts/sweetAlert";
import ChatBot from "../../lib/comment/comment";
import VideoPreview from "./videoPreview";

const validationRules = {
  // time: "required|string",
  // sets: "required|numeric",
  // repeat: "required|numeric",
  // weight: "required|numeric",
  // note: "required|string",
};

export default function EditAffectExercice({
  closeModalFunction,
  onSuccessFunction = () => {},
  username,
  affectedExerciceId,
}) {
  Validator.useLang("fr");
  const [validationErrors, setValidationErrors] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [affectedExerciceData, setAffectedExerciceData] = useState({
    time: "",
    sets: "",
    repeat: "",
    weight: "",
    note: "",
    days: "",
    exercice: {
      _id: "",
      name: "",
      description: "",
    },
  });
  const baseUrl = environment.API_URL;
  const CloseModalAffectExercice = () => {
    closeModalFunction();
  };
  const exerciceAdherentService = new ExerciceAdherentService();

  const handleInputChange = (value, fields) => {
    setAffectedExerciceData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  };
  const validation = new Validator(affectedExerciceData, validationRules);
  // validation.setAttributeNames({
  //   time: "temps",
  //   sets: "sets",
  //   repeat: "répitition",
  //   weight: "poids",
  //   note: "note",
  // });
  async function updateAffectedExerciceToAdherent() {
    if (validation.passes()) {
      const data = {
        time: affectedExerciceData.time,
        sets: affectedExerciceData.sets,
        repeat: affectedExerciceData.repeat,
        weight: affectedExerciceData.weight,
        note: affectedExerciceData.note,
      };
      try {
        await exerciceAdherentService.updateAffectedExercice(
          affectedExerciceId,
          data
        );
        showAlert({
          title: "exercice affecté à un adhérent modifié avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalAffectExercice();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur de modification est survenue",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const getExerciceAffectedDetails = async () => {
    try {
      await exerciceAdherentService
        .getAffectedExerciceById(affectedExerciceId)
        .then((response) => {
          setAffectedExerciceData(response.data);
          setIsLoading(false);
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  useEffect(() => {
    getExerciceAffectedDetails();
  }, []);
  return (
    <>
      <Modal
        onCancelFunction={CloseModalAffectExercice}
        onCloseFunction={CloseModalAffectExercice}
        submitFunction={updateAffectedExerciceToAdherent}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Adhérents"
            href="/adherents"
            activatedLinkTitle={"Modifier le planning de " + username}
            secondLinkTitle="Modifier un adhérent"
            color="titleModal"
          />
        }
        applyTitle="Enregistrer"
        isOpened={true}
        dialogContentChild={
          !isLoading ? (
            <div className="row">
              <div className="col-7 ">
                <h4>
                  Exercice :
                  <span className="titleExrcice ms-2">
                    {affectedExerciceData.exercice.name}
                  </span>
                </h4>

                <div className="d-flex align-items-center mt-3">
                  <div className=" me-1">
                    <label htmlFor="picture__input">
                      <img
                        src={
                          baseUrl +
                          "exercices/" +
                          affectedExerciceData.exercice._id +
                          "/ExplicatifImage"
                        }
                        className="picture__img"
                      />
                    </label>
                  </div>
                  <div className=" ms-1">
                    <label htmlFor="video__input">
                      <video
                        src={
                          baseUrl +
                          "exercices/" +
                          affectedExerciceData.exercice._id +
                          "/video"
                        }
                        alt="Uploaded Video"
                        className="video__img "
                        onClick={() => {
                          setOpenModal(true);
                        }}
                      />
                    </label>
                  </div>
                  {openModal && (
                    <VideoPreview
                      closeModalFunction={closeModal}
                      exerciceId={affectedExerciceData.exercice._id}
                    />
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between pt-4">
                  <div class="styled" id="target">
                    <div className="d-flex flex-column text-center">
                      <InputGroup
                        type="time"
                        id="standard"
                        name="time"
                        title="Temps"
                        classNames="form-control-input-group-time"
                        value={affectedExerciceData.time}
                        onChangeFunction={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column text-center">
                    <label for="standard" className="fs-6 text-muted">
                      Sets
                    </label>
                    <IncrementInput
                      aria-label="Quantity Input"
                      value={affectedExerciceData.sets}
                      name="sets"
                      onChangeFunction={handleInputChange}
                    />
                  </div>
                  <div className="d-flex flex-column text-center">
                    <label for="standard" className="fs-6 text-muted">
                      Repétition
                    </label>
                    <IncrementInput
                      aria-label="Quantity Input"
                      name="repeat"
                      value={affectedExerciceData.repeat}
                      onChangeFunction={handleInputChange}
                    />
                  </div>
                  <div className="d-flex flex-column text-center">
                    <label for="standard" className="fs-6 text-muted">
                      Poids
                    </label>
                    <IncrementInput
                      aria-label="Quantity Input"
                      value={affectedExerciceData.weight}
                      name="weight"
                      onChangeFunction={handleInputChange}
                    />
                  </div>
                </div>

                <TextAreaForm
                  rowNum={3}
                  title="Note :"
                  name="note"
                  value={affectedExerciceData.note}
                  onChangeFunction={handleInputChange}
                />
              </div>
              <div className="col-5">
                <div className="pb-5 pt-4">
                  <div className="ps-3">
                    <h6>Description :</h6>
                    <span>{affectedExerciceData.exercice.description}</span>
                  </div>
                  <div className="ps-3 py-2 title_comment">Commentaires :</div>
                  <ChatBot
                    affectedExerciceId={affectedExerciceId}
                    adherentId={affectedExerciceData.adherent}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        }
      />
    </>
  );
}
