import { CheckCircle, Pen, PlusCircle, Search } from "react-bootstrap-icons";
import Button from "../lib/button/button";
import SelectForm from "../lib/select/select";
import TableContent from "../lib/table/table";
import { useEffect, useState } from "react";
import showAlert from "../lib/Alerts/sweetAlert";
import MenuContent from "../lib/Menu/menu";
import InputGroup from "../lib/input-group/input-group";
import { accountStatusOptions } from "../../utils/variables/accountStatusOptions";
import { environment } from "../../environments/environment";
import "../assets/css/global.css";
import AddAdherent from "../components/adherent/addAdherent";
import EditAdherent from "../components/adherent/editAdherent";
import DetailsAdherent from "../components/adherent/detailsAdherent";
import AdherentService from "../../services/adherent.service";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const columns = [
  "",
  "Nom",
  "Prénom",
  "Username",
  "Téléphone",
  "Affecté à",
  "Statuts de compte",
  "Abonnement",
  "",
];

const coachColumns = [
  "",
  "Username",
  "Sexe",
  "Pays",
  "Ville",
  "Abonnement",
  "",
];

export default function Adherents() {
  const connectedUser = useSelector((store) => store.user.user);
  const baseUrl = environment.API_URL;
  const adherentService = new AdherentService();
  const [originalAdherents, setOriginalAdherent] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const navigate = useNavigate();
  const [filterOptions, setFilterOptions] = useState({
    accountStatus: "",
    state: "",
    search: "",
  });
  const [openModal, setOpenModal] = useState("");

  useEffect(() => {
    displayAdherentsList();
  }, []);

  useEffect(() => {
    filter();
  }, [filterOptions]);

  const filter = () => {
    let filtredData = originalAdherents;
    if (filterOptions.accountStatus !== "") {
      filtredData = filtredData.filter((adherent) =>
        JSON.stringify(adherent[6].props.children.props.children[0])
          .toLowerCase()
          .includes(filterOptions.accountStatus.toLowerCase())
      );
    }

    if (filterOptions.search !== "") {
      filtredData = filtredData.filter((adherent) => {
        return (
          adherent &&
          JSON.stringify(adherent)
            .toLowerCase()
            .includes(filterOptions.search.toLowerCase())
        );
      });
    }
    setAdherents(filtredData);
  };

  function search(searchQuery) {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        search: searchQuery,
      };
    });
  }

  const displayAdherentsList = async () => {
    try {
      await adherentService
        .getAllAdherents()
        .then((response) => {
          const data = response.data.map((adherent) =>
            formatAdherentData(adherent)
          );
          setAdherents(data);
          setOriginalAdherent(data);
        })
        .catch((err) => {
          console.log(err);
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const updateStatusAdherent = async (adherentId, status) => {
    try {
      showAlert({
        title: "vous êtes sûr ?",
        iconType: "question",
        confirmButtonText: "oui",
        cancelButtonText: "Non",
        showCancelButton: true,
        titleSuccess: "Statuts modifiée avec succées",
        iconSuccess: "success",
        confirmFunction: async () => {
          await adherentService.updateStatus(adherentId, {
            accountStatus: status,
          });
          if (status == "suspended") {
            return navigate("/archive");
          }
          displayAdherentsList();
        },
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const calculateRemainingDays = (createdAt, subscriptionDays) => {
    const formattedDateAjout = new Date(createdAt).toDateString();
    const formattedAddTime = new Date(formattedDateAjout).getTime();
    const currentDate = new Date().getTime();
    const differenceInMilliseconds = currentDate - formattedAddTime;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );
    const remainingDays = subscriptionDays - differenceInDays;

    return remainingDays;
  };
  const formatAdherentData = (adherent) => {
    let remainingDays = 0;
    if (adherent.adhSub !== undefined) {
      remainingDays = calculateRemainingDays(
        adherent.adhSub[0]?.createdAt,
        adherent.adhSub[0]?.subscriptionInDays
      );
    }
    const menuOptions = [
      <div>
        <Button
          title={"modifier"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => {
            setOpenModal(
              <EditAdherent
                closeModalFunction={closeModal}
                onSuccessFunction={displayAdherentsList}
                adherent={adherent}
                remainingDays={remainingDays}
              />
            );
          }}
        />
      </div>,
      <div>
        {connectedUser?.role !== "coach" && (
          <Button
            title={"détails"}
            classNames="bg-white border-0 itemBtn"
            onClickFunction={() => {
              setOpenModal(
                <DetailsAdherent
                  closeModalFunction={closeModal}
                  adherent={adherent}
                />
              );
            }}
          />
        )}
      </div>,
    ];
    const StatusMenuOptions = [
      <div>
        <Button
          title={"Vérifié"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => updateStatusAdherent(adherent._id, "verified")}
        />
      </div>,
      <div>
        <Button
          title={"Suspendu"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() =>
            updateStatusAdherent(adherent._id, "suspended")
          }
        />
      </div>,
      <div>
        <Button
          title={"En pause"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => updateStatusAdherent(adherent._id, "paused")}
        />
      </div>,
    ];
    const statusTextClass =
      adherent.accountStatus === "verified"
        ? "Vérifié"
        : adherent.accountStatus === "pending"
        ? "Attente de vérification"
        : adherent.accountStatus === "suspended"
        ? "Suspendu"
        : adherent.accountStatus === "paused"
        ? "En pause"
        : "";
    const accountStatusClass =
      adherent.accountStatus === "verified"
        ? "status_verified"
        : adherent.accountStatus === "paused"
        ? "status_paused"
        : adherent.accountStatus === "pending"
        ? "status_pending"
        : adherent.accountStatus === "suspended"
        ? "status_suspended"
        : "";
    let subscriptionDaysStyle =
      adherent.adhSub[0]?.isExpired === "yes"
        ? "red_style"
        : adherent.adhSub[0]?.subscriptionInDays > 100
        ? "purple_style"
        : adherent.adhSub[0]?.subscriptionInDays > 5
        ? "yellow_style"
        : "red_style";

    if (connectedUser?.role !== "coach") {
      return [
        <img
          src={baseUrl + "adherents/" + adherent._id + "/image"}
          className="default_user_img"
        />,
        adherent.lastname,
        adherent.firstname,
        adherent.username,
        adherent.phone,
        connectedUser?.role === "admin"
          ? adherent.coachs[0]?.username ?? "-"
          : "",
        connectedUser?.role === "admin" ? (
          <span className={accountStatusClass}>
            <div className="d-flex align-items-center">
              {statusTextClass}
              {connectedUser?.role !== "coach" ? (
                <MenuContent
                  btnContent={<Pen className="icon_status" />}
                  menuItemOptions={StatusMenuOptions}
                />
              ) : (
                ""
              )}
            </div>
          </span>
        ) : (
          ""
        ),
        connectedUser?.role === "admin" ? (
          <div className={subscriptionDaysStyle}>
            {adherent.adhSub[0]?.isExpired === "yes"
              ? "expiré"
              : remainingDays
              ? remainingDays + " Jours"
              : "-"}
          </div>
        ) : (
          ""
        ),
        connectedUser?.role === "admin" ? (
          <MenuContent
            btnContent={<CheckCircle className="icon_actions" />}
            menuItemOptions={menuOptions}
          />
        ) : (
          ""
        ),
      ];
    } else {
      return [
        <img
          src={baseUrl + "adherents/" + adherent._id + "/image"}
          className="default_user_img"
        />,
        adherent.username,
        adherent?.details[0]?.sexe === "male"
          ? "Homme"
          : adherent?.details[0]?.sexe === "female"
          ? "Femme"
          : "-",
        adherent.country,
        adherent.city,
        <div className={subscriptionDaysStyle}>
          {adherent.adhSub[0]?.isExpired === "yes"
            ? "expiré"
            : remainingDays
            ? remainingDays + " Jours"
            : "-"}
        </div>,
        <MenuContent
          btnContent={<CheckCircle className="icon_actions" />}
          menuItemOptions={menuOptions}
        />,
      ];
    }
  };

  const filteringStatus = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        accountStatus: value,
      };
    });
  };

  const closeModal = () => {
    setOpenModal("");
  };

  const resetFilters = () => {
    setFilterOptions({
      accountStatus: "",
      state: "",
      search: "",
    });
  };

  return (
    <div className="me-2">
      <div className="fw-bolder fs-4 pt-3">
        <a href="/adherents" className="text-decoration-none text-black">
          Adhérents
        </a>
      </div>
      <div>
        <InputGroup
          classNames="form-control-input-group-search "
          type={"text"}
          placeholder="Recherche"
          iconStart={<Search />}
          input_style="inputStyleSearch"
          value={filterOptions.search}
          onChangeFunction={search}
        />
      </div>
      <div className="d-flex align-items-center mt-4">
        <div className="d-flex justify-content-start align-items-center pe-5">
          <SelectForm
            onChangeFunction={filteringStatus}
            options={accountStatusOptions}
            value={filterOptions.accountStatus}
            labelFilter="Statuts de compte"
            classNames="filtered_adherent_select "
            selectStyle="label_select_status"
          />
        </div>
        <div>
          <Button
            title={"Effacer filtres"}
            color="secondary"
            classNames="clearFilterButton p-0"
            onClickFunction={resetFilters}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-2">
        {connectedUser?.role !== "coach" && (
          <Button
            title={"Ajouter"}
            color="success"
            classNames="add_button"
            iconStart={<PlusCircle className="icon_add" />}
            onClickFunction={() => {
              setOpenModal(
                <AddAdherent
                  closeModalFunction={closeModal}
                  onSuccessFunction={displayAdherentsList}
                />
              );
            }}
          />
        )}
      </div>
      <TableContent
        columns={connectedUser?.role !== "coach" ? columns : coachColumns}
        data={adherents}
        rowsPerPage={5}
        totalRows={adherents.length}
      />
      {openModal}
    </div>
  );
}
