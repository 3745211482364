import { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import UserService from "../../../services/user.service";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import { villes } from "../../../utils/variables/villesOptions";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { environment } from "../../../environments/environment";
import TableContent from "../../lib/table/table";
import { Pencil, PlusCircle } from "react-bootstrap-icons";
import ChipsArray from "../../lib/chips/chipsArray";
import CoachAdherentService from "../../../services/coachAdherent.service";
import AdherentService from "../../../services/adherent.service";
import { subscriptionsOptions } from "../../../utils/variables/subscriptionsOptions";
import DatePicker from "../../lib/datePicker/datePicker";
import { addDays } from "date-fns";
import { fr } from "date-fns/locale";
import Button from "../../lib/button/button";
const columns = [
  "",
  "Nom",
  "Prénom",
  "E-mail",
  "Téléphone",
  "Username",
  "Rejoindre",
  "Periode d’abonnement",
  "",
];
const defaultDateRange = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
    locale: fr,
  },
];
const validationRules = {
  firstname: "required|string",
  lastname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  username: "required",
  city: "required|string",
  address: "required",
};

const userService = new UserService();
const coachAdherentService = new CoachAdherentService();
const adherentService = new AdherentService();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function EditCoach({
  coach = {},
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const baseUrl = environment.API_URL;
  const [userData, setUserData] = useState(coach);
  const [validationErrors, setValidationErrors] = useState({});
  const [value, setValue] = useState(0);
  const [originalAdherents, setOriginalAdherent] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const [imageUrl, setImageUrl] = useState(
    baseUrl + "users/" + coach._id + "/image"
  );
  const [openFilter, setOpenFilter] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    subscriptions: "",
    date: "",
    state: "",
  });

  useEffect(() => {
    filter();
  }, [filterOptions]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  Validator.useLang("fr");

  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }

  const validation = new Validator(userData, validationRules);

  validation.setAttributeNames({
    firstname: "prénom",
    lastname: "nom",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
    city: "City",
    address: "Adress",
  });

  const CloseModalUpdateCoach = () => {
    closeModalFunction();
  };
  const filter = () => {
    let filtredData = originalAdherents;
    if (filterOptions.subscriptions !== "") {
      filtredData = filtredData.filter((adherent) =>
        JSON.stringify(adherent[7].props.children)
          .toLowerCase()
          .includes(filterOptions.subscriptions.toLowerCase())
      );
    }
    if (filterOptions.date !== "") {
      const startDate = filterOptions.date[0].startDate;
      const endDate = filterOptions.date[0].endDate;
      filtredData = filtredData.filter((adherent) => {
        const dateParts = adherent[6].split("/");
        const createdAt = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );

        return createdAt >= startDate && createdAt <= endDate;
      });
    }
    setAdherents(filtredData);
  };
  const handleDateChange = (selectedDates) => {
    if (selectedDates) {
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          date: selectedDates,
        };
      });
    }
  };
  const resetFilters = () => {
    setFilterOptions({
      subscriptions: "",
      date: "",
      state: "",
    });
  };
  const filteringSubscriptions = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        subscriptions: value,
      };
    });
  };
  const openModalFilterDate = () => {
    setOpenFilter(true);
  };

  const closeModalFilterDate = () => {
    setOpenFilter(false);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserData((prevState) => {
      return {
        ...prevState,
        path: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  async function updateCoach() {
    if (validation.passes()) {
      const updatedCoach = new FormData();
      updatedCoach.append("path", userData.path);
      updatedCoach.append("firstname", userData.firstname);
      updatedCoach.append("lastname", userData.lastname);
      updatedCoach.append("email", userData.email);
      updatedCoach.append("phone", userData.phone);
      updatedCoach.append("username", userData.username);
      updatedCoach.append("country", "Tunisie");
      updatedCoach.append("city", userData.city);
      updatedCoach.append("address", userData.address);
      try {
        await userService.updateUser(coach._id, updatedCoach);
        showAlert({
          title: "Coach modifié avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalUpdateCoach();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur de modification est survenue",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const displayAdherentsList = async () => {
    try {
      await adherentService
        .getAvailableAdherents()
        .then((response) => {
          const data = response.data.map((adherent) =>
            formatAdherentData(adherent)
          );
          setAdherents(data);
          setOriginalAdherent(data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const formatDate = (createdAt) => {
    const formattedDate = new Date(createdAt).toLocaleDateString("en-GB");
    return formattedDate;
  };
  const formatAdherentData = (adherent) => {
    const formattedDate = formatDate(adherent.createdAt);
    return [
      <img
        src={baseUrl + "adherents/" + adherent._id + "/image"}
        className="affect_adherent_img"
      />,
      adherent.lastname,
      adherent.firstname,
      adherent.email,
      adherent.phone,
      adherent.username,
      formattedDate,
      <div className="text-center fw-bolder">
        {adherent.adhSub[0] ? adherent.adhSub[0].period + " Mois" : "-"}
      </div>,
      <PlusCircle
        className="icon_affectAdherent"
        onClick={() => handleAddAdherentToCoach(adherent)}
      />,
    ];
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const getAffectedAdherents = async () => {
    try {
      await coachAdherentService
        .getAdherentsAffected(coach._id)
        .then((response) => {
          const data = response.data.map((adherent) =>
            formataffectedAdherentData(adherent)
          );
          setChipData(data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const formataffectedAdherentData = (adherent) => {
    return {
      avatar: `${baseUrl}adherents/${adherent.adherent._id}/image`,
      label: `${adherent.adherent.firstname} ${adherent.adherent.lastname} (${adherent.adherent.username})`,
      key: adherent.adherent._id,
    };
  };
  const handleAddAdherentToCoach = async (adherent) => {
    try {
      const payload = {
        coachId: coach._id,
        username: adherent.username,
      };
      const response = await coachAdherentService.affectAdherentToCoach(
        payload
      );

      if (response.status === 200) {
        // const updatedAdherents = adherents.filter((item) => {
        //   return item[5] !== adherent.username;
        // });
        // setAdherents(updatedAdherents);
        // setChipData((prevChips) => [
        //   ...prevChips,
        //   {
        //     avatar: `${baseUrl}adherents/${adherent._id}/image`,
        //     key: adherent._id,
        //     label: `${adherent.firstname} ${adherent.lastname} (${adherent.username})`,
        //   },
        // ]);
        displayAdherentsList();
      }
    } catch (error) {
      console.error("Error affecting adherent:", error);
    }
  };
  const disaffectAdherent = async (adherentId) => {
    try {
      await coachAdherentService.disaffectAdherent(adherentId, coach._id);
      displayAdherentsList();
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  useEffect(() => {
    displayAdherentsList();
    getAffectedAdherents();
  }, []);

  return (
    <>
      <Modal
        onCancelFunction={CloseModalUpdateCoach}
        onCloseFunction={CloseModalUpdateCoach}
        submitFunction={updateCoach}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Coachs"
            href="/coachs"
            activatedLinkTitle="Modifier Un coach"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="Vertical tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    height: "7px",
                    backgroundColor: "#14CA81",
                  },
                  "& .Mui-selected": {
                    color: "black !important",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%",
                  },
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "space-between",
                  },
                }}
              >
                <Tab label="Informations générales" {...a11yProps(0)} />
                <Tab
                  label={`Adhérents(${chipData.length})`}
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="row">
                  <div className="col-6">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageChange}
                        />
                        <label htmlFor="imageUpload">
                          <Pencil className="edit_avatar_img" />
                        </label>
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="clock"
                          style={{ backgroundImage: `url(${imageUrl})` }}
                        ></div>
                      </div>
                    </div>
                    <InputGroup
                      classNames="form-control-input-group-add"
                      title="Nom"
                      type="text"
                      name="lastname"
                      value={userData.lastname}
                      onChangeFunction={handleInputChange}
                    />
                    {validationErrors && validationErrors.lastname && (
                      <AlertMessages
                        message={validationErrors.lastname}
                        messageType="error"
                      />
                    )}
                    <InputGroup
                      classNames="form-control-input-group-add"
                      title="Prénom"
                      type="text"
                      name="firstname"
                      value={userData.firstname}
                      onChangeFunction={handleInputChange}
                    />
                    {validationErrors && validationErrors.firstname && (
                      <AlertMessages
                        message={validationErrors.firstname}
                        messageType="error"
                      />
                    )}
                    <InputGroup
                      classNames="form-control-input-group-add"
                      title="Téléphone"
                      type="tel"
                      value={userData.phone}
                      onChangeFunction={handleInputChange}
                      name="phone"
                    />
                    {validationErrors && validationErrors.phone && (
                      <AlertMessages
                        message={validationErrors.phone}
                        messageType="error"
                      />
                    )}

                    {/* <InputGroup
                classNames="form-control-input-group-add"
                title="Mot de passe"
                type="password"
                value={userData.password}
                onChangeFunction={handleInputChange}
                name="password"
              />
              {validationErrors && validationErrors.password && (
                <AlertMessages
                  message={validationErrors.password}
                  messageType="error"
                />
              )} */}
                  </div>
                  <div className="col-6">
                    <InputGroup
                      classNames="form-control-input-group-add"
                      title="Email"
                      type="email"
                      value={userData.email}
                      onChangeFunction={handleInputChange}
                      name="email"
                    />
                    {validationErrors && validationErrors.email && (
                      <AlertMessages
                        message={validationErrors.email}
                        messageType="error"
                      />
                    )}
                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Username"
                      type="text"
                      value={userData.username}
                      onChangeFunction={handleInputChange}
                      name="username"
                    />
                    {validationErrors && validationErrors.username && (
                      <AlertMessages
                        message={validationErrors.username}
                        messageType="error"
                      />
                    )}
                    <SelectForm
                      disabled={true}
                      onChangeFunction={handleInputChange}
                      value={""}
                      name="country"
                      labelFilter="Tunisie"
                      classNames="addUser_select"
                      titleSelect="Pays"
                    />
                    <SelectForm
                      onChangeFunction={handleInputChange}
                      options={villes}
                      name="city"
                      value={userData.city}
                      labelFilter="Ville"
                      classNames="addUser_select"
                      titleSelect="Ville"
                      MenuProps={MenuProps}
                    />
                    {validationErrors && validationErrors.city && (
                      <AlertMessages
                        message={validationErrors.city}
                        messageType="error"
                      />
                    )}
                    <InputGroup
                      classNames="form-control-input-group-add"
                      title="Adresse"
                      type="text"
                      name="address"
                      value={userData.address}
                      onChangeFunction={handleInputChange}
                    />
                    {validationErrors && validationErrors.address && (
                      <AlertMessages
                        message={validationErrors.address}
                        messageType="error"
                      />
                    )}
                  </div>
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <div className="pb-2 fw-bolder fs-6 text-black">
                  Adhérents afféctés : {chipData.length}
                </div>
                <ChipsArray
                  chipData={chipData}
                  setChipData={setChipData}
                  avatar={chipData}
                  diplayDelete={true}
                  deleteFunction={disaffectAdherent}
                />

                <div className="py-2  fw-bolder fs-6 text-black">
                  Affecter des adhérents :
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="d-flex justify-content-start  pb-4  pe-5">
                    <Button
                      title={"Date"}
                      classNames="modalButton me-3"
                      onClickFunction={openModalFilterDate}
                    />
                    <Modal
                      onCancelFunction={closeModalFilterDate}
                      onCloseFunction={closeModalFilterDate}
                      submitFunction={closeModalFilterDate}
                      applyTitle="filtrer"
                      isOpened={openFilter}
                      cancelText="annuler"
                      dialogContentChild={
                        <DatePicker
                          onChangeFunction={handleDateChange}
                          date={
                            filterOptions.date !== ""
                              ? filterOptions.date
                              : defaultDateRange
                          }
                        />
                      }
                    />
                    <SelectForm
                      onChangeFunction={filteringSubscriptions}
                      options={subscriptionsOptions}
                      value={filterOptions.subscriptions}
                      labelFilter="Periode d’abonnement"
                      classNames="subscriptions_select"
                      selectStyle="label_select_status"
                    />
                  </div>
                  <div>
                    <Button
                      title={"Effacer filtres"}
                      color="secondary"
                      classNames="clearFilterButtonSubscription p-0"
                      onClickFunction={resetFilters}
                    />
                  </div>
                </div>
                <Box
                  sx={{
                    border: "1px solid gainsboro",
                    borderRadius: "12px",
                  }}
                >
                  <TableContent
                    columns={columns}
                    data={adherents}
                    rowsPerPage={10}
                    totalRows={adherents.length}
                    className="table_affect_adherent"
                  />
                </Box>
              </TabPanel>
            </Box>
          </>
        }
      />
    </>
  );
}
