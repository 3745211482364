import { Fragment, useEffect, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import { Bell } from "react-bootstrap-icons";
import Pusher from "pusher-js";
import showToast from "../lib/Alerts/toast";
import { useSelector } from "react-redux";
import LogService from "../../services/log.service";
import showAlert from "../lib/Alerts/sweetAlert";
import { Badge, Divider, Menu, MenuItem } from "@mui/material";
import "../layout/sideBar.css";
import { useNavigate } from "react-router-dom";
export default function NavBar({ open, setOpen }) {
  const connectedUser = useSelector((store) => store.user.user);
  const logService = new LogService();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [alertNotif, setAlertNotif] = useState(null);
  const openMenu = Boolean(alertNotif);
  const handleClick = (event) => {
    setAlertNotif(event.currentTarget);
  };
  const handleClose = () => {
    setAlertNotif(null);
  };
  const toggleToListNotificaion = () => {
    navigate("/notifications");
  };
  const getNotifications = async () => {
    try {
      await logService
        .getAllNotifications()
        .then((response) => {
          const data = response.data.slice(0, 5);
          setNotifications(data);
        })
        .catch((error) => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  useEffect(() => {
    getNotifications();
    var pusher = new Pusher("7303e2f984710a021a01", {
      cluster: "eu",
    });

    var channel;
    if (connectedUser.role === "admin") {
      channel = pusher.subscribe("admin");
      channel.bind("admin", function (data) {
        showToast({
          title: `${data.message}`,
          iconType: "success",
        });
      });
    } else {
      channel = pusher.subscribe(connectedUser.username);
      channel.bind(connectedUser.username, function (data) {
        showToast({
          title: `${data.message}`,
          iconType: "success",
        });
      });
    }
  }, []);
  function formatHour(dateString) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }
  function formatDate(dateString) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const day = date.getDate().toString().padStart(2, "0"); // Get day with zero-padding
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month with zero-padding (months are 0-indexed)
    const year = date.getFullYear();

    return `${day}/${month}/${year} `;
  }
  return (
    <Navbar className="bg-white navBar_height ">
      <Container fluid className={open ? "partial-navbar" : "full-navbar"}>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <Fragment>
              <Badge
                color="secondary"
                badgeContent={5}
                sx={{
                  "& .MuiBadge-badge": {
                    backgroundColor: "#14ca81",
                  },
                }}
                onClick={handleClick}
                aria-controls={openMenu ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
              >
                <Bell className="alert_notif" />
              </Badge>

              <Menu
                anchorEl={alertNotif}
                id="account-menu"
                open={openMenu}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Divider />
                <MenuItem className="border-bottom">
                  <span className="fw-bold fs-5">Notifications</span>
                </MenuItem>
                {notifications.map((log, key) => (
                  <MenuItem onClick={handleClose}>
                    <div className="d-flex justify-content-between" key={key}>
                      <div className="ps-2 date_notif">
                        {formatDate(log.createdAt)}
                      </div>
                      <span className="log-username">{log.message}</span>
                      <div className="pe-3 date_notif">
                        {formatHour(log.createdAt)}
                      </div>
                    </div>
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={toggleToListNotificaion}
                  className="border-bottom  text-center notifList"
                >
                  <div className="fw-bolder fs-6  ">Voir tous</div>
                </MenuItem>
              </Menu>
            </Fragment>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
