import { useEffect, useState } from "react";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import { environment } from "../../../environments/environment";
import SalleService from "../../../services/salle.service";
import showAlert from "../../lib/Alerts/sweetAlert";

export default function DetailsAdherent({ adherent = {}, closeModalFunction }) {
  const baseUrl = environment.API_URL;
  const salleService = new SalleService();
  const [userData] = useState(adherent);
  const [salles, setSalles] = useState([]);
  const [imageUrl] = useState(baseUrl + "adherents/" + adherent._id + "/image");
  const CloseModalDetailsAdherent = () => {
    closeModalFunction();
  };
  const getAllSalles = async () => {
    try {
      await salleService.getAllSalles().then((response) => {
        const data = response.data;
        setSalles(data);
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAllSalles();
  }, []);
  return (
    <>
      <Modal
        onCancelFunction={CloseModalDetailsAdherent}
        onCloseFunction={CloseModalDetailsAdherent}
        submitFunction={CloseModalDetailsAdherent}
        cancelText="retour"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Adhérents"
            href="/adherents"
            activatedLinkTitle="détails d'un adhérent"
            color="titleModal"
          />
        }
        applyTitle=""
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="avatar-preview">
                  <div
                    id="clock"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </div>
              </div>
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Nom"
                type="text"
                name="lastname"
                value={userData.lastname}
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Prénom"
                type="text"
                name="firstname"
                value={userData.firstname}
              />
            </div>
            <div className="col-6">
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Téléphone"
                type="tel"
                value={userData.phone}
                name="phone"
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Email"
                type="email"
                value={userData.email}
                name="email"
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Username"
                type="text"
                value={userData.username}
                name="username"
              />
              <SelectForm
                disabled={true}
                options={salles.map((salle) => salle.firstname)}
                value={userData.salle}
                labelFilter="Agent"
                name="agent"
                classNames="addUser_select"
                titleSelect="Agent"
              />
            </div>
          </div>
        }
      />
    </>
  );
}
