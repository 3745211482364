import { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import showAlert from "../../lib/Alerts/sweetAlert";
import { environment } from "../../../environments/environment";
import AdherentService from "../../../services/adherent.service";
import { Box, Tab, Tabs } from "@mui/material";
import { TabPanel, a11yProps } from "../../lib/tabPanel/TabPanel";
import { useNavigate } from "react-router-dom";

const adherentService = new AdherentService();

const baseUrl = environment.API_URL;

export default function ArchiveDetailsAdherent({
  adherent = {},
  closeModalFunction,
  onSuccessFunction = () => {},
}) {
  const [userData, setUserData] = useState(adherent);
  const [value, setValue] = useState(0);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [adherentData, setAdherentData] = useState();

  const [imageUrl, setImageUrl] = useState(
    baseUrl + "adherents/" + adherent._id + "/image"
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const CloseModalDetailsAdherent = () => {
    closeModalFunction();
  };

  const getQuestionnaire = async () => {
    try {
      await adherentService.getResponses(adherent._id).then((response) => {
        const data = response.data;
        setQuestionnaire(data);
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const getInformationsAdherent = async () => {
    try {
      await adherentService
        .getAdherentInformation(adherent._id)
        .then((response) => {
          const data = response.data;
          setUserDetails(data);
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const getDetailsAdherent = async () => {
    try {
      await adherentService
        .getAdherentDetails(adherent._id)
        .then((response) => {
          const data = response.data;
          data.dayEnum = data.days.map((day) => {
            return day === "monday"
              ? "Lun"
              : day === "tuesday"
              ? "Mar"
              : day === "wednesday"
              ? "Mer"
              : day === "thursday"
              ? "Jeu"
              : day === "friday"
              ? "Ven"
              : day === "saturday"
              ? "Sam"
              : day === "sunday"
              ? "Dim"
              : "";
          });
          setAdherentData(data);
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  useEffect(() => {
    getQuestionnaire();
    getInformationsAdherent();
    getDetailsAdherent();
  }, []);
  return (
    <>
      <Modal
        onCancelFunction={CloseModalDetailsAdherent}
        onCloseFunction={CloseModalDetailsAdherent}
        submitFunction={CloseModalDetailsAdherent}
        cancelText="Retour"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Archive"
            href="/archive"
            activatedLinkTitle="détails adhérent "
            color="titleModal"
          />
        }
        isOpened={true}
        dialogContentChild={
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="Vertical tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  height: "7px",
                  backgroundColor: "#14CA81",
                },
                "& .Mui-selected": {
                  color: "black !important",
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "100%",
                },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Tab label="Informations générales" {...a11yProps(0)} />
              <Tab label={`Informations d’adhérant`} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className="row">
                <div className="col-6">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                      />
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="clock"
                        style={{ backgroundImage: `url(${imageUrl})` }}
                      ></div>
                    </div>
                  </div>
                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Nom"
                    type="text"
                    name="lastname"
                    value={userData.lastname}
                  />

                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Prénom"
                    type="text"
                    name="firstname"
                    value={userData.firstname}
                  />
                </div>
                <div className="col-6">
                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Téléphone"
                    type="tel"
                    value={userData.phone}
                    name="phone"
                  />

                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Email"
                    type="email"
                    value={userData.email}
                    name="email"
                  />
                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Username"
                    type="text"
                    value={userData.username}
                    name="username"
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <div className="row mb-3 border-bottom">
                  <div className="col-sm-6 col-md-4 col-lg-4 ">
                    <div className="row my-2 field-label">
                      <div className="col-auto">Nom :</div>
                      <div className="col-auto">
                        <div className="d-inline-flex">
                          <strong>
                            {userData?.firstname || "non renseigné"}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto">Age :</div>
                      <div className="col  ">
                        <div className="d-inline-flex ">
                          <strong>
                            {adherentData?.age || "non renseigné"} ans
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto">Pays :</div>
                      <div className="col">
                        <div className="d-inline-flex ">
                          <strong>
                            {userData?.country || "non renseigné"}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto">Ville :</div>
                      <div className="col ">
                        <div className="d-inline-flex">
                          <strong>{userData?.city || "non renseigné"}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4  ">
                    <div className="row my-2 field-label">
                      <div className="col-auto">Affecté à : </div>
                      <div className="col">
                        <div className="d-inline-flex  px-2">
                          <strong></strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto">Poid :</div>
                      <div className="col-6">
                        <div className="d-inline-flex">
                          <strong>
                            {adherentData?.weight
                              ? adherentData.weight + " kg"
                              : "non renseigné"}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto ">Longeur :</div>
                      <div className="col  ">
                        <div className="d-inline-flex">
                          <strong>
                            {adherentData?.height
                              ? adherentData?.height + " cm"
                              : "non renseigné"}{" "}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto ">Valabilité d’abonnement:</div>
                      <div className="col  ">
                        <div className="d-inline-flex">
                          <strong>90 Jours</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4  ">
                    <div className="row my-2 field-label">
                      <div className="col-auto">Profession </div>
                      <div className="col">
                        <div className="d-inline-flex  px-2">
                          <strong>
                            {adherentData?.profession || "non renseigné"}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mb-1 field-label">
                      <div className="col-auto">Jours par semaine :</div>
                    </div>
                    <div className="row mb-2 field-label">
                      <div className="col-auto text-muted d-flex justify-between">
                        {adherentData?.days
                          ? adherentData?.dayEnum + ","
                          : "non renseigné"}
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto">Heures par séance :</div>
                      <div className="col">
                        <div className="d-inline-flex">
                          <strong>
                            {adherentData?.hourByseance || "non renseigné"}
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="row my-2 field-label">
                      <div className="col-auto">Temps disponible :</div>
                      <div className="col  ">
                        <div className="d-inline-flex">
                          <strong>
                            {adherentData?.timingFree || "non renseigné"}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 p-2">
                    <div className="row my-2 field-label">
                      <div className="col-auto ">Genre :</div>
                      <div className="col-auto ">
                        <div className="d-inline-flex px-2">
                          <strong>
                            {adherentData?.sexe === "female"
                              ? "femme"
                              : "homme"}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  {questionnaire.map((reponse, key) => {
                    return (
                      <div className="col-sm-6 col-md-4 col-lg-3 p-2" key={key}>
                        <div className="row my-2 field-label ">
                          <div className="col-auto question_adherent">
                            {reponse?.question}
                          </div>
                          <div className="col-auto ">
                            <div className="d-inline-flex px-2">
                              <strong>{reponse?.text}</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </TabPanel>
          </Box>
        }
      />
    </>
  );
}
