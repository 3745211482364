import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import Button from "../button/button";

export default function MenuContent({ menuItemOptions = [], btnContent = "" }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        iconStart={btnContent}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClickFunction={handleClick}
        classNames="border-0 btn_actions"
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItemOptions.map((option, key) => (
          <MenuItem key={key} onClick={handleClose} className="itemBtn">
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
