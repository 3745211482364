import { Breadcrumbs, Link, Typography } from "@mui/material";

export default function BreadcrumbsLink({
  firstLinkTitle = "",
  secondLinkTitle = "",
  activatedLinkTitle = "",
  color = "",
  href = "",
}) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link underline="hover" color="text.primary" href={href}>
        {firstLinkTitle}
      </Link>
      {secondLinkTitle && <Link underline="hover" color="text.primary" href={href}>
        {secondLinkTitle}
      </Link>}
      <Typography className={color}>{activatedLinkTitle}</Typography>
    </Breadcrumbs>
  );
}
