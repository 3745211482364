import Swal from "sweetalert2";

export default function showToast({ title = "", iconType = "" }) {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
  Toast.fire({
    icon: iconType,
    title: title,
  });
}
