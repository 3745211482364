import { useEffect, useState } from "react";
import SubscriptionPricesService from "../../services/subscriptionPrices.service";
import "../assets/css/pack.css";
import showAlert from "../lib/Alerts/sweetAlert";
import InputGroup from "../lib/input-group/input-group";
import TextAreaForm from "../lib/textArea/textAreaForm";
import Button from "../lib/button/button";
export default function Packs() {
  const subscriptionPricesService = new SubscriptionPricesService();
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const displaySubscriptionsPack = async () => {
    try {
      await subscriptionPricesService
        .getAllSubscriptionPacks()
        .then((response) => {
          setSubscriptions(response.data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const handleSubscriptionClick = (subscription) => {
    setSelectedSubscription(subscription);
  };

  async function updatePackInformations(subscription) {
    try {
      await subscriptionPricesService.changePackInformations(
        subscription.period,
        subscription
      );
      displaySubscriptionsPack();
      showAlert({
        title: "Pack changé avec succès",
        iconType: "success",
        cancelButtonText: "ok",
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue lors de la modification",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  }
  useEffect(() => {
    displaySubscriptionsPack();
  }, []);

  function handleInputChange(value, fields) {
    setSelectedSubscription((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }

  return (
    <>
      <div className="fw-bolder fs-4 pt-3">
        <a href="/packs" className="text-decoration-none text-black">
          Packs
        </a>
      </div>
      <div class="container_pack">
        <article class="section_pack">
          <section class="row_pack ">
            {subscriptions.map((subscription, index) => {
              return (
                <section
                  class="pricing-card pricing-card--shadow pricing-card--best"
                  onClick={() => handleSubscriptionClick(subscription)}
                  key={index}
                >
                  <div class="pricing-card__summary">
                    <div class="pricing-card__head">
                      <div class="pricing-card__tier fw-bolder">
                        {subscription.title}
                      </div>
                      {subscription.tag === "" ? (
                        ""
                      ) : (
                        <div class="pricing-card__tag px-3 py-1 ">
                          {subscription.tag}
                        </div>
                      )}
                    </div>

                    <ul class="pricing-card__features pt-4">
                      <li class="pricing-card__feature">
                        <span className="pack_description">
                          {subscription.description}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div class="pt-5">
                    {subscription.recentPrice === 0 ? (
                      <p></p>
                    ) : (
                      <div class="d-flex justify-content-end text-decoration-line-through-red text-black">
                        <span className="prices">
                          {subscription.recentPrice} Dt
                        </span>
                      </div>
                    )}
                    <div class=" d-flex justify-content-end fw-bold text-black fs-2">
                      <span className="prices">
                        {subscription.currentPrice} Dt
                      </span>
                    </div>
                    <div class=" d-flex justify-content-end text-black">
                      <span className="prices">
                        {subscription.DetailsPrice}
                      </span>
                    </div>
                  </div>
                </section>
              );
            })}
          </section>
        </article>
      </div>
      {selectedSubscription && (
        <div className="row">
          <div className="col-md-6 px-5">
            <InputGroup
              classNames="form-control-input-group-pack mb-3"
              title="Titre"
              type="text"
              name="title"
              value={selectedSubscription.title}
              onChangeFunction={handleInputChange}
            />
            <TextAreaForm
              rowNum={9}
              title="Détails"
              name="description"
              value={selectedSubscription.description}
              onChangeFunction={handleInputChange}
            />
          </div>
          <div className="col-md-6 px-5">
            <InputGroup
              classNames="form-control-input-group-pack mb-3"
              title="Tag"
              type="text"
              name="tag"
              value={selectedSubscription.tag}
              onChangeFunction={handleInputChange}
            />
            <InputGroup
              classNames="form-control-input-group-pack mb-3"
              title="Prix"
              type="text"
              name="currentPrice"
              value={selectedSubscription.currentPrice}
              onChangeFunction={handleInputChange}
            />
            <InputGroup
              classNames="form-control-input-group-pack mb-3"
              title="Prix barré"
              type="text"
              name="recentPrice"
              value={selectedSubscription.recentPrice}
              onChangeFunction={handleInputChange}
            />
            <InputGroup
              classNames="form-control-input-group-pack mb-3"
              title="Prix détail"
              type="text"
              name="DetailsPrice"
              value={selectedSubscription.DetailsPrice}
              onChangeFunction={handleInputChange}
            />
            <div className="d-flex justify-content-end mb-3">
              <Button
                title={"Changer un pack"}
                color="success"
                classNames="add_button"
                onClickFunction={() => {
                  updatePackInformations(selectedSubscription);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
