import React, { useState } from "react";
import "../modal/modal.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
export default function Modal({
  dialogContentChild,
  onCloseFunction = () => {},
  onCancelFunction = () => {},
  submitFunction = () => {},
  isOpened = false,
  dialogTitle,
  applyTitle = "",
  dialogText = "",
  cancelText = "",
}) {
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("md");
  return (
    <>
      <div>
        <Dialog
          open={isOpened}
          onClose={onCloseFunction}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
        >
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>
            {dialogContentChild}
          </DialogContent>
          <DialogActions
            sx={{
              "&.MuiDialogActions-root": {
                justifyContent: "flex-start",
              },
            }}
          >
            {applyTitle ? (
              <Button onClick={submitFunction} className="store_button">
                {applyTitle}
              </Button>
            ) : (
              ""
            )}
            <Button onClick={onCancelFunction} className="cancel_button">
              {cancelText}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
