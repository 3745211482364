import axios from "axios";
import { environment } from "../environments/environment";

export default class ForgetPasswordService {
  async sendEmail(data) {
    return await axios.post(environment.API_URL + "forget-password", {
      email: data,
    });
  }
  async verifyCode(data) {
    return await axios.post(
      environment.API_URL + "forget-password/codeVerification",
      { token: data }
    );
  }
  async changePassword(data) {
    return await axios.put(
      environment.API_URL + "forget-password/change",
      data
    );
  }
}
