import axios from "axios";
import { environment } from "../environments/environment";

export default class ExerciceService {
  addExercice = async (data) => {
    return axios.post(environment.API_URL + "exercices/create", data);
  };

  getAllExercices = async () => {
    return axios.get(environment.API_URL + "exercices");
  };

  editExercice = async (exerciceId, data) => {
    return axios.post(
      environment.API_URL + `exercices/update/${exerciceId}`,
      data
    );
  };
  getexercicesByCategory = async () => {
    return axios.get(environment.API_URL + "exercices/categories");
  };
}
