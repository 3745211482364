import { useState } from "react";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import { villes } from "../../../utils/variables/villesOptions";
import { environment } from "../../../environments/environment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DetailsCoach({ coach = {}, closeModalFunction }) {
  const baseUrl = environment.API_URL;
  const [userData, setUserData] = useState(coach);
  const [imageUrl] = useState(baseUrl + "users/" + coach._id + "/image");

  const CloseModaldetailsUser = () => {
    closeModalFunction();
  };

  return (
    <>
      <Modal
        onCancelFunction={CloseModaldetailsUser}
        onCloseFunction={CloseModaldetailsUser}
        submitFunction={CloseModaldetailsUser}
        cancelText="retour"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Coachs"
            href="/coachs"
            activatedLinkTitle="détails d'un coach"
            color="titleModal"
          />
        }
        applyTitle=""
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-6">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                </div>
                <div className="avatar-preview">
                  <div
                    id="clock"
                    style={{ backgroundImage: `url(${imageUrl})` }}
                  ></div>
                </div>
              </div>
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Nom"
                type="text"
                name="lastname"
                value={userData.lastname}
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Prénom"
                type="text"
                name="firstname"
                value={userData.firstname}
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Téléphone"
                type="tel"
                value={userData.phone}
                name="phone"
              />
            </div>
            <div className="col-6">
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Email"
                type="email"
                value={userData.email}
                name="email"
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Username"
                type="text"
                value={userData.username}
                name="username"
              />
              <SelectForm
                disabled={true}
                value={""}
                name="country"
                labelFilter="Tunisie"
                classNames="addUser_select"
                titleSelect="Pays"
              />
              <SelectForm
                disabled={true}
                options={villes}
                name="city"
                value={userData.city}
                labelFilter="Ville"
                classNames="addUser_select"
                titleSelect="Ville"
                MenuProps={MenuProps}
              />
              <InputGroup
                disabled={true}
                classNames="form-control-input-group-add"
                title="Adresse"
                type="text"
                name="address"
                value={userData.address}
              />
            </div>
          </div>
        }
      />
    </>
  );
}
