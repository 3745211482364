import React, { useState } from "react";
import Input from "../lib/input/input";
import "../assets/css/login.css";
import Card from "react-bootstrap/Card";
import logoDryfit from "../assets/images/logoDryFit.png";
import Button from "../lib/button/button";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import ForgetPasswordService from "../../services/forgetPassword.service";

const forgetPasswordService = new ForgetPasswordService();
export default function ForgetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState({
    email: "",
  });
  const handleEmailChange = (value) => {
    setEmail(value);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const result = emailRegex.test(email);
    if (!result) {
      setErrMsg((prevErrMsg) => ({
        ...prevErrMsg,
        email: result ? "" : "Le champ email contient un format invalide",
      }));
    }
    return result;
  };

  async function forgetPassword() {
    try {
      setErrMsg({
        email: "",
      });

      if (validateEmail(email)) {
        await forgetPasswordService
          .sendEmail(email)
          .then(() => {
            return navigate("/codeVerify");
          })
          .catch((error) => {
            setErrMsg((prevErrMsg) => ({
              ...prevErrMsg,
              account: error.response.data.message,
            }));
            console.log(error);
          });
      }
    } catch (error) {
      console.error(error);
    }
  }
  const toggleToLogin = () => {
    return navigate(`/login`);
  };
  return (
    <div className="login-background">
      <div className="d-flex justify-content-center align-items-center h-100">
        <Card>
          <Card.Body>
            <div className="text-center">
              <img className="pt-4 " src={logoDryfit} />
            </div>
            <h5 className="py-5 text-center">
              Merci d'entrer votre adresse e-mail
            </h5>
            <div className="d-flex flex-column align-items-center">
              <Input
                classNames="form-control-input "
                title="Email"
                type="email"
                placeholder="utilisateur@gmail.com"
                onChangeFunction={handleEmailChange}
                errorMessage={errMsg.email}
              />
              <Button
                title={"Envoyer"}
                color="success"
                classNames="formButton mt-4"
                onClickFunction={forgetPassword}
              />
              <Button
                title={"retour à la page login"}
                classNames="forgetPasswordBtn my-3"
                onClickFunction={toggleToLogin}
                iconStart={<ArrowLeft />}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
