import React, { useEffect, useState } from "react";
import TableContent from "../lib/table/table";
import UserService from "../../services/user.service";
import { CheckCircle, PlusCircle, Search } from "react-bootstrap-icons";
import Button from "../lib/button/button";
import SelectForm from "../lib/select/select";
import Modal from "../components/modal/modal";
import DatePicker from "../lib/datePicker/datePicker";
import { addDays } from "date-fns";
import { fr } from "date-fns/locale";
import InputGroup from "../lib/input-group/input-group";
import AddUser from "../components/user/addUser";
import MenuContent from "../lib/Menu/menu";
import EditUser from "../components/user/editUser";
import { useNavigate } from "react-router-dom";
import showAlert from "../lib/Alerts/sweetAlert";
import DetailsUser from "../components/user/detailsUser";
import { options } from "../../utils/variables/roleOptions";
import { environment } from "../../environments/environment";
import { useSelector } from "react-redux";

const columns = [
  "",
  "Nom",
  "Prénom",
  "E-mail",
  "Téléphone",
  "Username",
  "Ajouté",
  "Role",
  "",
];

const defaultDateRange = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
    locale: fr,
  },
];

export default function Users() {
  const userService = new UserService();
  const connectedUser = useSelector((store) => store.user.user);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    role: "",
    date: "",
    state: "",
    search: "",
  });
  const baseUrl = environment.API_URL;
  const [openFilter, setOpenFilter] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    filter();
  }, [filterOptions]);

  const filter = () => {
    let filtredData = originalUsers;
    if (filterOptions.role !== "") {
      filtredData = filtredData.filter(
        (user) => user[7].props.children === filterOptions.role
      );
    }
    if (filterOptions.date !== "") {
      const startDate = filterOptions.date[0].startDate;
      const endDate = filterOptions.date[0].endDate;
      filtredData = filtredData.filter((user) => {
        const dateParts = user[6].split("/");
        const createdAt = new Date(
          +dateParts[2],
          dateParts[1] - 1,
          +dateParts[0]
        );

        return createdAt >= startDate && createdAt <= endDate;
      });
    }

    if (filterOptions.search !== "") {
      filtredData = filtredData.filter((user) => {
        return (
          user &&
          JSON.stringify(user)
            .toLowerCase()
            .includes(filterOptions.search.toLowerCase())
        );
      });
    }
    setUsers(filtredData);
  };

  function search(searchQuery) {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        search: searchQuery,
      };
    });
  }

  const getAllUsers = async () => {
    try {
      await userService
        .getAllUsers()
        .then((response) => {
          const data = response.data.map((user) => formatUserData(user));
          setUsers(data);
          setOriginalUsers(data);
        })
        .catch((error) => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const toggleUserStatus = async (userId) => {
    try {
      showAlert({
        title: "vous êtes sûr ?",
        iconType: "question",
        confirmButtonText: "oui",
        cancelButtonText: "Non",
        showCancelButton: true,
        titleSuccess: "Utilisateur désactivé avec succés",
        iconSuccess: "success",
        confirmFunction: async () => {
          await userService.disableUser(userId);
          return navigate("/archive");
        },
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const formatUserData = (user) => {
    const formattedDate = formatDate(user.createdAt);
    const menuOptions = [
      <div>
        <Button
          title={"modifier"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => {
            setOpenModal(
              <EditUser
                closeModalFunction={closeModal}
                onSuccessFunction={getAllUsers}
                user={user}
              />
            );
          }}
        />
      </div>,
      <>
        {connectedUser?.role === "admin" && (
          <Button
            title={"désactiver"}
            classNames=" bg-white border-0 itemBtn"
            onClickFunction={() => toggleUserStatus(user._id)}
          />
        )}
      </>,
      <div>
        <Button
          title={"détails"}
          classNames="bg-white border-0 itemBtn"
          onClickFunction={() => {
            setOpenModal(
              <DetailsUser closeModalFunction={closeModal} user={user} />
            );
          }}
        />
      </div>,
    ];
    const roleClass =
      user.role === "admin"
        ? "role_admin_table"
        : user.role === "editeur"
          ? "role_editor"
          : user.role === "coach"
            ? "role_coach"
            : user.role === "agent"
              ? "role_salle"
              : "";
    return [
      <img
        src={baseUrl + "users/" + user._id + "/image"}
        className="default_user_img"
      />,
      user.lastname !== undefined ? user.lastname : "-",
      user.firstname,
      user.email,
      user.phone,
      user.username,
      formattedDate,
      <span className={roleClass}>{user.role}</span>,
      <MenuContent
        btnContent={<CheckCircle className="icon_actions" />}
        menuItemOptions={menuOptions}
      />,
    ];
  };

  const formatDate = (createdAt) => {
    const formattedDate = new Date(createdAt).toLocaleDateString("en-GB");
    return formattedDate;
  };

  const filteringRole = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        role: value,
      };
    });
  };

  const openModalFilterDate = () => {
    setOpenFilter(true);
  };

  const closeModalFilterDate = () => {
    setOpenFilter(false);
  };

  const closeModal = () => {
    setOpenModal("");
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates) {
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          date: selectedDates,
        };
      });
    }
  };

  const resetFilters = () => {
    setFilterOptions({
      role: "",
      date: "",
      state: "",
      search: "",
    });
  };

  return (
    <>
      <div className="me-2">
        <div className="fw-bolder fs-4 pt-3">
          <a href="/users" className="text-decoration-none text-black">
            Utilisateurs
          </a>
        </div>
        <div>
          <InputGroup
            classNames="form-control-input-group-search "
            type={"text"}
            placeholder="Recherche"
            iconStart={<Search />}
            input_style="inputStyleSearch"
            value={filterOptions.search}
            onChangeFunction={search}
          />
        </div>
        <div className="d-flex align-items-center mt-3">
          <div className="d-flex justify-content-start align-items-center pe-5">
            <Button
              title={"Date"}
              classNames="modalButton me-3"
              onClickFunction={openModalFilterDate}
            />
            <Modal
              onCancelFunction={closeModalFilterDate}
              onCloseFunction={closeModalFilterDate}
              submitFunction={closeModalFilterDate}
              applyTitle="filtrer"
              isOpened={openFilter}
              cancelText="annuler"
              dialogContentChild={
                <DatePicker
                  onChangeFunction={handleDateChange}
                  date={
                    filterOptions.date !== ""
                      ? filterOptions.date
                      : defaultDateRange
                  }
                />
              }
            />
            <SelectForm
              onChangeFunction={filteringRole}
              options={options}
              value={filterOptions.role}
              labelFilter="Role"
              classNames="filtered_select "
              selectStyle="label_select"
            />
          </div>
          <div>
            <Button
              title={"Effacer filtres"}
              color="secondary"
              classNames="clearFilterButton p-0"
              onClickFunction={resetFilters}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <Button
            title={"Ajouter"}
            color="success"
            classNames="add_button"
            iconStart={<PlusCircle className="icon_add" />}
            onClickFunction={() => {
              setOpenModal(
                <AddUser
                  closeModalFunction={closeModal}
                  onSuccessFunction={getAllUsers}
                />
              );
            }}
          />
        </div>
        <TableContent
          columns={columns}
          data={users}
          rowsPerPage={5}
          totalRows={users.length}
        />
        {openModal}
      </div>
    </>
  );
}
