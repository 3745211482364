import React, { useContext, useEffect, useState } from "react";

import NavLinkItem from "../lib/navLinkItem/navLinkItem";
import "./sideBar.css";
import image from "../assets/images/admin.jpg";
import coach from "../assets/images/coach.png";
import exercice from "../assets/images/exercise.png";
import gym from "../assets/images/salle.png";
import subscription from "../assets/images/abonnement.png";

import access from "../assets/images/access.png";
import { connect, useSelector } from "react-redux";
import {
  ArchiveFill,
  BoxArrowRight,
  GearFill,
  PeopleFill,
  UniversalAccess,
} from "react-bootstrap-icons";
import { checkAuth, logout } from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/actions/action";
import showToast from "../lib/Alerts/toast";
import LogoOpen from "../assets/images/logoNavBar.png";
import LogoClose from "../assets/images/logoMin.png";
import Button from "../lib/button/button";
import { environment } from "../../environments/environment";

function SideBar({ open, setOpen }) {
  const connectedUser = useSelector((store) => store.user.user);
  const navigate = useNavigate();
  const baseUrl = environment.API_URL;

  useEffect(() => {
    const handleResize = () => {
      const body = document.body;
      setOpen(window.innerWidth > 991);

      if (window.innerWidth > 991) {
        body.classList.remove("toggle-sidebar");
      } else {
        body.classList.add("toggle-sidebar");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logOut = async () => {
    logout()
      .then((response) => {
        showToast({
          title: `vous êtes déconnecté(e) `,
          iconType: "success",
        });
        return navigate("/login");
      })
      .catch((error) => {
        showToast({
          title: `Une erreur est survenue"`,
          iconType: "error",
        });
        console.log(error);
      });
  };

  return (
    <aside id="sidebar" className=" sidebar sidebar_img ps-2">
      <div className="row  py-4 ">
        <div className="col-10 d-flex justify-content-center">
          {open ? (
            <img src={LogoOpen} height={30} />
          ) : (
            <img src={LogoClose} width={55} />
          )}
        </div>
        <div className="col-2 d-flex justify-content-end pe-0">
          <button
            id="toggleButton"
            className="btn bg-white shadow-sm px-1"
            onClick={() => {
              const body = document.body;
              if (body.classList.contains("toggle-sidebar")) {
                setOpen(true);
              } else {
                setOpen(false);
              }
              body.classList.toggle("toggle-sidebar");
            }}
          >
            {open ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M10.4712 4.47206C10.7316 4.21171 10.7316 3.7896 10.4712 3.52925C10.2109 3.2689 9.78878 3.2689 9.52843 3.52925L5.52843 7.52925C5.26808 7.7896 5.26808 8.21171 5.52843 8.47205L9.52843 12.4721C9.78878 12.7324 10.2109 12.7324 10.4712 12.4721C10.7316 12.2117 10.7316 11.7896 10.4712 11.5292L6.94265 8.00065L10.4712 4.47206Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M10.4712 4.47206C10.7316 4.21171 10.7316 3.7896 10.4712 3.52925C10.2109 3.2689 9.78878 3.2689 9.52843 3.52925L5.52843 7.52925C5.26808 7.7896 5.26808 8.21171 5.52843 8.47205L9.52843 12.4721C9.78878 12.7324 10.2109 12.7324 10.4712 12.4721C10.7316 12.2117 10.7316 11.7896 10.4712 11.5292L6.94265 8.00065L10.4712 4.47206Z"
                  fill="black"
                  transform="rotate(180, 8, 8)"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <div className="py-4 px-2 d-flex align-items-center flex-column justify-content-center border-top">
        {open ? (
          <>
            {connectedUser !== "" ? (
              <>
                <img
                  className="rounded-circle w-25 pb-2"
                  src={baseUrl + "users/" + connectedUser?.id + "/image"}
                />
                <span className="name_admin pb-1">{connectedUser?.name}</span>
                <span className="role_admin">{connectedUser?.role}</span>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <img
              className="rounded-circle w-50 pb-2"
              src={baseUrl + "users/" + connectedUser?.id + "/image"}
            />
            <span className="name_admin_sideBar_closed pb-1">
              {connectedUser?.name}
            </span>
            <span className="role_admin_sideBar_closed">
              {connectedUser?.role}
            </span>
          </>
        )}
      </div>
      {(connectedUser.role === "admin" || connectedUser.role === "editeur") && (
        <ul className="sidebar-nav border-top" id="sidebar-nav">
          <NavLinkItem
            title="Utilisateurs"
            navigateTo="/users"
            isOpened={open}
            iconStart={<PeopleFill />}
          />
          <NavLinkItem
            title="Coachs"
            navigateTo="/coachs"
            isOpened={open}
            iconStart={<img height={20} src={coach} alt="Coach" />}
          />
          <NavLinkItem
            title="Adhérents"
            navigateTo="/adherents"
            isOpened={open}
            iconStart={<UniversalAccess />}
          />
          <NavLinkItem
            title="Exercices"
            navigateTo="/exercices"
            isOpened={open}
            iconStart={<img height={20} src={exercice} alt="exercice" />}
          />
          <NavLinkItem
            title="Agents"
            navigateTo="/agents"
            isOpened={open}
            iconStart={<img height={20} src={gym} alt="Agents" />}
          />
          <NavLinkItem
            title="Packs"
            navigateTo="/packs"
            isOpened={open}
            iconStart={
              <img height={20} src={subscription} alt="abonnements dryfit" />
            }
          />
          <NavLinkItem
            title="Archieve"
            navigateTo="/archive"
            isOpened={open}
            iconStart={<ArchiveFill />}
          />
          <NavLinkItem
            title="Connexions"
            navigateTo="/logs"
            isOpened={open}
            iconStart={<img height={20} src={access} alt="connexions" />}
          />
          <NavLinkItem
            title="Notifications"
            navigateTo="/notifications"
            isOpened={open}
            iconStart={<img height={20} src={access} alt="notifications" />}
          />
        </ul>
      )}
      {connectedUser.role === "agent" && (
        <ul className="sidebar-nav border-top" id="sidebar-nav">
          <NavLinkItem
            title="Abonnement"
            navigateTo="/subscriptions"
            isOpened={open}
            iconStart={<PeopleFill />}
          />
        </ul>
      )}
      {connectedUser.role === "coach" && (
        <ul className="sidebar-nav border-top" id="sidebar-nav">
          <NavLinkItem
            title="Adhérents"
            navigateTo="/adherents"
            isOpened={open}
            iconStart={<UniversalAccess />}
          />
          <NavLinkItem
            title="Exercices"
            navigateTo="/exercices"
            isOpened={open}
            iconStart={<img height={20} src={exercice} alt="exercice" />}
          />
          <NavLinkItem
            title="Notifications"
            navigateTo="/notifications"
            isOpened={open}
            iconStart={<img height={20} src={access} alt="notifications" />}
          />
        </ul>
      )}
      <ul className="sidebar-nav text-center pb-3" id="logout_sidebar_button">
        <NavLinkItem
          title="Paramètres"
          navigateTo="/settings"
          isOpened={open}
          iconStart={<GearFill />}
        />
        <li className="nav-item m-0 d-flex justify-content-center">
          {open ? (
            <Button
              onClickFunction={logOut}
              title={"Déconnexion"}
              classNames="nav-link collapsed "
            />
          ) : (
            <BoxArrowRight onClick={logOut} className="logout" />
          )}
        </li>
      </ul>
    </aside>
  );
}
const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(SideBar);
