import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ChevronDown } from "react-bootstrap-icons";

export default function AccordionForm({
  titleAccordion = "",
  accordionContentChild,
}) {
  return (
    <div>
      <Accordion
        sx={{
          border: "1px solid #BCBCBC",
          borderRadius: "7px !important",
          boxShadow: "none",
          marginBottom: "4px",
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronDown className="icon_accordion" />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            "& .MuiTypography-root": {
              fontWeight: 600,
            },
          }}
        >
          <Typography>{titleAccordion}</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type": {
              marginBottom: "6px",
            },
          }}
        >
          <Typography>{accordionContentChild}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
