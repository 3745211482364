import axios from "axios";
import { environment } from "../environments/environment";

export default class SalleService {
  getAllSalles = async () => {
    return axios.get(environment.API_URL + "agents/enabled");
  };
  getDisabledSalle = async (salleId) => {
    return axios.get(environment.API_URL + `agents/disabled/${salleId}`);
  };
  addSalle = async (data) => {
    return axios.post(environment.API_URL + "agents/add", data);
  };
}
