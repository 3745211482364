import { Alert } from "@mui/material";

export default function AlertMessages({ message = "", messageType = "" }) {
  return (
    <Alert
      severity={messageType}
      className="mt-2"
      sx={{
        "&.MuiAlert-root": {
          backgroundColor: "#FFF",
          color: "#db5353",
          padding: 0,
          fontSize: "0.85rem",
        },
      }}
    >
      {message}
    </Alert>
  );
}
