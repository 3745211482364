import { useEffect, useRef, useState } from "react";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import "../../assets/css/global.css";
import "../Exercice/addExercice.css";
import "../exerciceAdherent/affectExercice.css";
import IncrementInput from "../../lib/incrementInput/numberIncrementInput";
import TextAreaForm from "../../lib/textArea/textAreaForm";
import ExerciceService from "../../../services/exercice.service";
import AccordionForm from "../../lib/accordion/accordion";
import { environment } from "../../../environments/environment";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import ExerciceAdherentService from "../../../services/exerciceAdherent.service";
import InputGroup from "../../lib/input-group/input-group";

export default function AffectExercice({
  closeModalFunction,
  onSuccessFunction = () => {},
  username,
  adherentId,
  selectedDay,
  week,
  displayedExercices,
}) {
  const [exercicesByCategory, setExercicesByCategory] = useState({});
  const [exercices, setExercices] = useState({});
  const [affectedExerciceData, setAffectedExerciceData] = useState({
    time: "00:00",
    sets: "",
    repeat: "",
    weight: "",
    note: "",
    days: "",
  });
  const baseUrl = environment.API_URL;
  const CloseModalAffectExercice = () => {
    closeModalFunction();
  };
  const exerciceService = new ExerciceService();
  const exerciceAdherentService = new ExerciceAdherentService();

  const displayExercicesByCategories = async () => {
    try {
      const response = await exerciceService.getexercicesByCategory();
      const formattedData = {};
      for (const key in response.data.exercices) {
        if (Object.hasOwnProperty.call(response.data.exercices, key)) {
          const exercice = response.data.exercices[key];
          const filteredExercices = exercice.filter(
            (item) => !displayedExercices.includes(item.name)
          );

          if (filteredExercices.length > 0) {
            formattedData[key] = filteredExercices;
          }
        }
        setExercicesByCategory(formattedData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const displayExerciceDetails = async (exercice) => {
    const confirmRemove = window.confirm(
      "Êtes-vous sûr de vouloir ajouter cet exercice à l'adhérent et le supprimer de la liste ?"
    );
    if (!confirmRemove) {
      return;
    }

    const updatedExercicesByCategory = { ...exercicesByCategory };
    for (const category in updatedExercicesByCategory) {
      updatedExercicesByCategory[category] = updatedExercicesByCategory[
        category
      ].filter((item) => item._id !== exercice._id);
    }
    setExercicesByCategory(updatedExercicesByCategory);

    setExercices(exercice);
  };
  const handleInputChange = (value, fields) => {
    setAffectedExerciceData((prevState) => {
      if (fields === "repeat" && affectedExerciceData.repeat >= 0) {
        return {
          ...prevState,
          time: "00:00",
          repeat: value,
        };
      } else if (fields === "time" && affectedExerciceData.time !== "00:00") {
        return {
          ...prevState,
          repeat: 0,
          time: value,
        };
      } else {
        return {
          ...prevState,
          [fields]: value,
        };
      }
    });
  };
  const AffectExerciceToAdherent = async () => {
    try {
      const payload = {
        adherent: adherentId,
        exercice: exercices._id,
        time: affectedExerciceData.time,
        sets: affectedExerciceData.sets,
        repeat: affectedExerciceData.repeat,
        weight: affectedExerciceData.weight,
        note: affectedExerciceData.note,
        days: selectedDay,
        week: week,
      };
      const response = await exerciceAdherentService.affectExerciceToAdherent(
        payload
      );
    } catch (error) {
      console.error("Error affecting adherent:", error);
    }
    onSuccessFunction();
  };
  const returnToCategory = () => {
    const updatedExercicesByCategory = { ...exercicesByCategory };
    const category = exercices.category;
    updatedExercicesByCategory[category] = [
      ...updatedExercicesByCategory[category],
      exercices,
    ];
    setExercicesByCategory(updatedExercicesByCategory);
    setExercices("");
  };
  useEffect(() => {
    displayExercicesByCategories();
  }, []);

  return (
    <>
      <Modal
        onCancelFunction={CloseModalAffectExercice}
        onCloseFunction={CloseModalAffectExercice}
        submitFunction={AffectExerciceToAdherent}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Adhérents"
            href="/adherents"
            activatedLinkTitle={"Ajouter exercice de " + username}
            secondLinkTitle="Modifier un adhérent"
            color="titleModal"
          />
        }
        applyTitle="Enregistrer"
        isOpened={true}
        dialogContentChild={
          <div className="row">
            <div className="col-8">
              {exercices.name && exercices.name.length > 0 ? (
                <div className="d-flex align-items-center">
                  <h4>
                    Exercice :
                    <span className="titleExrcice px-2">{exercices.name}</span>
                  </h4>
                  <DashCircle
                    className="icon_cancelExerciceCategories "
                    onClick={returnToCategory}
                  />
                </div>
              ) : (
                <h4>
                  Exercice :
                  <span className="titleExrcice">{exercices.name}</span>
                </h4>
              )}
              <div className="d-flex align-items-center mt-3">
                <div className=" me-1">
                  <label htmlFor="picture__input">
                    <img
                      src={
                        baseUrl +
                        "exercices/" +
                        exercices._id +
                        "/ExplicatifImage"
                      }
                      className="picture__img"
                    />
                  </label>
                </div>
                <div className=" ms-1">
                  <label htmlFor="video__input">
                    <video
                      src={baseUrl + "exercices/" + exercices._id + "/video"}
                      alt="Uploaded Image"
                      className="picture__img"
                    />
                  </label>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between pt-4">
                <div class="styled" id="target">
                  <div className="d-flex flex-column text-center">
                    <InputGroup
                      type="time"
                      id="standard"
                      name="time"
                      title="Temps"
                      classNames="form-control-input-group-time"
                      value={affectedExerciceData.time}
                      onChangeFunction={handleInputChange}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column text-center">
                  <label for="standard" className="fs-6 text-muted">
                    Sets
                  </label>
                  <IncrementInput
                    aria-label="Quantity Input"
                    minValue={0}
                    value={affectedExerciceData.sets}
                    name="sets"
                    onChangeFunction={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column text-center">
                  <label for="standard" className="fs-6 text-muted">
                    Repétition
                  </label>
                  <IncrementInput
                    aria-label="Quantity Input"
                    minValue={0}
                    name="repeat"
                    value={affectedExerciceData.repeat}
                    onChangeFunction={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column text-center">
                  <label for="standard" className="fs-6 text-muted">
                    Poids
                  </label>
                  <IncrementInput
                    aria-label="Quantity Input"
                    minValue={0}
                    value={affectedExerciceData.weight}
                    name="weight"
                    onChangeFunction={handleInputChange}
                  />
                </div>
              </div>
              <div className="pb-5 pt-4">
                <h6>Description :</h6>
                <span>{exercices.description}</span>
              </div>
              <TextAreaForm
                rowNum={5}
                title="Note :"
                name="note"
                value={affectedExerciceData.note}
                onChangeFunction={handleInputChange}
              />
            </div>
            <div className="col-4">
              <h6 className="title_list_categories">Exercices :</h6>
              {Object.entries(exercicesByCategory).map(
                ([category, exercises]) => (
                  <AccordionForm
                    key={category}
                    titleAccordion={category}
                    accordionContentChild={
                      <>
                        {exercises.map((exercice) => (
                          <div
                            key={exercice.id}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <img
                              src={
                                baseUrl +
                                "exercices/" +
                                exercice._id +
                                "/SimpleImage"
                              }
                              className="exerciceCategories_img"
                            />
                            {exercice.name}
                            <PlusCircle
                              className="icon_exerciceCategories"
                              onClick={() => displayExerciceDetails(exercice)}
                            />
                          </div>
                        ))}
                      </>
                    }
                  />
                )
              )}
            </div>
          </div>
        }
      />
    </>
  );
}
