import React, { useState } from "react";
import "./textareaForm.css";
import { InputGroup as IG, Form } from "react-bootstrap";
export default function TextAreaForm({
  title = "",
  name = "",
  rowNum,
  errorMessage = "",
  value = "",
  onChangeFunction = () => {},
}) {
  return (
    <>
      <Form.Group>
        <Form.Label className="fs-6 fw-normal text-muted">{title}</Form.Label>
        <Form.Control
          className="textArea_style"
          as="textarea"
          value={value}
          rows={rowNum}
          onChange={(e) => {
            onChangeFunction(e.target.value, name);
          }}
        />
        {errorMessage !== "" && <p className="text-danger">{errorMessage}</p>}
      </Form.Group>
    </>
  );
}
