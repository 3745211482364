import React, { useState, useRef, useEffect } from "react";
import "../NumberCodeInput/numberCodeInput.css";

export default function NumberCodeInput({ setToken }) {
  const [inputs, setInputs] = useState(Array(6).fill(""));
  const [isComplete, setIsComplete] = useState(false);
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    const filledInputs = inputs.filter((input) => input !== "");
    setIsComplete(filledInputs.length === inputs.length);
  }, [inputs]);

  const handleInputChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (index < 5) {
      inputRefs.current[index + 1].focus();
    }
    const newToken = newInputs.join("");
    setToken(newToken);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && inputs[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div>
      <fieldset name="number-code">
        {inputs.map((input, index) => (
          <input
            className={`codeInput text-center border-bottom${
              isComplete ? " green-border" : ""
            }`}
            key={index}
            type="number"
            min="0"
            max="1"
            value={input}
            ref={(el) => (inputRefs.current[index] = el)}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            required
          />
        ))}
      </fieldset>
    </div>
  );
}
