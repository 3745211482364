import react, { useEffect, useState } from "react";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import UserService from "../../../services/user.service";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import Validator from "validatorjs";
import AlertMessages from "../../lib/Alerts/alert";
import showAlert from "../../lib/Alerts/sweetAlert";
import { villes } from "../../../utils/variables/villesOptions";
import { environment } from "../../../environments/environment";
import { Pencil } from "react-bootstrap-icons";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import TableContent from "../../lib/table/table";
import * as React from "react";
import { monthOptions } from "../../../utils/variables/monthOptions";
import CustomToggleButton from "../../lib/ToggleButton/toggleButton";
import dataNotFound from "../../assets/images/dataNotFound.png";
const validationRules = {
  firstname: "required|string",
  email: "required|email",
  phone: "required|digits:8",
  username: "required",
  city: "required|string",
  address: "required",
};
const userService = new UserService();
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const columns = ["Code", "Date", "Activé", "Periode d’abonnement", "Montant"];
export default function EditSalle({
  salle = {},
  closeModalFunction,
  onSuccessFunction = () => { },
}) {
  const baseUrl = environment.API_URL;
  const [userData, setUserData] = useState(salle);
  const [validationErrors, setValidationErrors] = useState({});
  const [originalSubscriptions, setOriginalSubscriptions] = useState(salle);
  const [value, setValue] = useState(0);
  const [imageUrl, setImageUrl] = useState(
    baseUrl + "users/" + salle._id + "/image"
  );
  const [filterOptions, setFilterOptions] = useState({
    month: "Janvier",
    year: "",
    state: "",
  });
  const currentYear = new Date().getFullYear();

  const yearOptions = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
  ];

  Validator.useLang("fr");

  function handleInputChange(value, fields) {
    setUserData((prevState) => {
      return {
        ...prevState,
        [fields]: value,
      };
    });
  }
  const validation = new Validator(userData, validationRules);
  validation.setAttributeNames({
    firstname: "nom de l'agent",
    email: "email",
    phone: "téléphone",
    username: "nom utilisateur",
    city: "City",
    address: "Adress",
  });
  const CloseModalUpdateSalle = () => {
    closeModalFunction();
  };
  async function editSalle() {
    if (validation.passes()) {
      const updatedSalle = new FormData();
      updatedSalle.append("path", userData.path);
      updatedSalle.append("firstname", userData.firstname);
      updatedSalle.append("email", userData.email);
      updatedSalle.append("phone", userData.phone);
      updatedSalle.append("username", userData.username);
      updatedSalle.append("country", "Tunisie");
      updatedSalle.append("city", userData.city);
      updatedSalle.append("address", userData.address);
      try {
        await userService.updateUser(salle._id, updatedSalle);
        showAlert({
          title: "Agent modifié avec succés",
          iconType: "success",
          cancelButtonText: "ok",
        });
        onSuccessFunction();
        return CloseModalUpdateSalle();
      } catch (error) {
        showAlert({
          title: "Oups!! erreur",
          iconType: "error",
          text: "Une erreur de modification est survenue",
          cancelButtonText: "ok",
        });
        console.log("error", error);
      }
    } else {
      setValidationErrors(validation.errors.all());
    }
  }
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUserData((prevState) => {
      return {
        ...prevState,
        path: file,
      };
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const formatDate = (createdAt) => {
    const formattedDate = new Date(createdAt).toLocaleDateString("en-GB");
    return formattedDate;
  };
  function filterSubscriptions() {
    let filtredData = { ...originalSubscriptions };
    if (filterOptions.year !== "") {
      const newSubs = filtredData.ssub.filter((subscription) => {
        return (
          new Date(subscription.createdAt).getFullYear() === filterOptions.year
        );
      });
      filtredData.ssub = newSubs;
    }
    if (filterOptions.month !== "") {
      const selectedMonthIndex = monthOptions.findIndex(
        (month) => month === filterOptions.month
      );

      if (selectedMonthIndex !== -1) {
        const selectedMonth = selectedMonthIndex + 1;

        filtredData.ssub = filtredData.ssub.filter((subscription) => {
          const createdAtMonth =
            new Date(subscription.createdAt).getMonth() + 1;

          return createdAtMonth === selectedMonth;
        });
      } else {
        console.log("Invalid month name:", filterOptions.month);
      }
    }
    setUserData(filtredData);
  }
  const handleDateChange = (selectedDate) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        month: selectedDate,
      };
    });
  };

  const displaySubscriptions = (userData) => {
    if (userData && userData.ssub) {
      return userData.ssub.map((subscription) => {
        const formattedDate = formatDate(subscription.createdAt);
        return [
          <div className="fw-bolder">{subscription.code}</div>,
          formattedDate,
          subscription.isActivated,
          <div className="fw-bolder">{subscription.period}</div>,
          // subscription.montant,
          <div className="fw-bolder">{"70DT"}</div>,
        ];
      });
    }
  };
  const filteringYear = (value) => {
    setFilterOptions((prevState) => {
      return {
        ...prevState,
        year: value,
      };
    });
  };
  useEffect(() => {
    filterSubscriptions();
  }, [filterOptions]);

  useEffect(() => { }, [userData]);

  return (
    <>
      <Modal
        onCancelFunction={CloseModalUpdateSalle}
        onCloseFunction={CloseModalUpdateSalle}
        submitFunction={editSalle}
        cancelText="annuler"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Agents"
            href="/agents"
            activatedLinkTitle="Modifier Un Agent"
            color="titleModal"
          />
        }
        applyTitle="Sauvgarder"
        isOpened={true}
        dialogContentChild={
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="Vertical tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  height: "7px",
                  backgroundColor: "#14CA81",
                },
                "& .Mui-selected": {
                  color: "black !important",
                  fontSize: "14px",
                  fontWeight: "600",
                  width: "100%",
                },
                "& .MuiTabs-flexContainer": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Tab label="Informations générales" {...a11yProps(0)} />
              <Tab
                label={`Abonnements(${salle.ssub.length})`}
                {...a11yProps(1)}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className="row">
                <div className="col-6">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="imageUpload">
                        <Pencil className="edit_avatar_img" />
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="clock"
                        style={{ backgroundImage: `url(${imageUrl})` }}
                      ></div>
                    </div>
                  </div>
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Nom de l'agent"
                    type="text"
                    name="firstname"
                    value={userData.firstname}
                    onChangeFunction={handleInputChange}
                  />
                  {validationErrors && validationErrors.firstname && (
                    <AlertMessages
                      message={validationErrors.firstname}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Téléphone"
                    type="tel"
                    value={userData.phone}
                    onChangeFunction={handleInputChange}
                    name="phone"
                  />
                  {validationErrors && validationErrors.phone && (
                    <AlertMessages
                      message={validationErrors.phone}
                      messageType="error"
                    />
                  )}
                </div>
                <div className="col-6">
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Email"
                    type="email"
                    value={userData.email}
                    onChangeFunction={handleInputChange}
                    name="email"
                  />
                  {validationErrors && validationErrors.email && (
                    <AlertMessages
                      message={validationErrors.email}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    disabled={true}
                    classNames="form-control-input-group-add"
                    title="Username"
                    type="text"
                    value={userData.username}
                    onChangeFunction={handleInputChange}
                    name="username"
                  />
                  {validationErrors && validationErrors.username && (
                    <AlertMessages
                      message={validationErrors.username}
                      messageType="error"
                    />
                  )}
                  <SelectForm
                    disabled={true}
                    onChangeFunction={handleInputChange}
                    value={""}
                    name="country"
                    labelFilter="Tunisie"
                    classNames="addUser_select"
                    titleSelect="Pays"
                  />
                  <SelectForm
                    onChangeFunction={handleInputChange}
                    options={villes}
                    name="city"
                    value={userData.city}
                    labelFilter="Ville"
                    classNames="addUser_select"
                    titleSelect="Ville"
                    MenuProps={MenuProps}
                  />
                  {validationErrors && validationErrors.city && (
                    <AlertMessages
                      message={validationErrors.city}
                      messageType="error"
                    />
                  )}
                  <InputGroup
                    classNames="form-control-input-group-add"
                    title="Adresse"
                    type="text"
                    name="address"
                    value={userData.address}
                    onChangeFunction={handleInputChange}
                  />
                  {validationErrors && validationErrors.address && (
                    <AlertMessages
                      message={validationErrors.address}
                      messageType="error"
                    />
                  )}
                </div>
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <div className="filter-wrapper">
                <SelectForm
                  onChangeFunction={filteringYear}
                  options={yearOptions}
                  value={filterOptions.year}
                  labelFilter="Année"
                  classNames="filtered_select_year mb-2"
                  selectStyle="label_select_status"
                />
                <CustomToggleButton
                  options={monthOptions}
                  onChangeFunction={handleDateChange}
                  value={filterOptions.month}
                  name="month"
                />
              </div>
              {userData.ssub.length === 0 ? (
                <div className="text-center">
                  <img className="w-50" src={dataNotFound} />
                  <h4 className="text-center">Il n'existe pas des abonnements à ce mois</h4>
                </div>
              ) : (
                <Box
                  sx={{
                    border: "1px solid gainsboro",
                    borderRadius: "12px",
                  }}
                >
                  <TableContent
                    columns={columns}
                    data={displaySubscriptions(userData)}
                    rowsPerPage={10}
                    totalRows={userData.ssub.length}
                    className="table_affect_adherent"
                  />
                </Box>
              )}
            </TabPanel>
          </Box>
        }
      />
    </>
  );
}
