import React, { useState } from "react";
import { Avatar, Chip, Paper, styled } from "@mui/material";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
export default function ChipsArray({
  chipData,
  setChipData,
  diplayDelete = true,
  deleteFunction = () => {},
}) {
  const handleDelete = (chipToDelete) => () => {
    deleteFunction(chipToDelete.key);
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
        "&.MuiPaper-root": {
          border: "1px solid gainsboro",
          borderRadius: "12px",
          marginBottom: "10px",
          boxShadow: "none",
        },
      }}
      component="ul"
    >
      {chipData.map((data) => {
        return (
          <ListItem key={data.key}>
            {diplayDelete ? (
              <Chip
                avatar={<Avatar alt="Avatar" src={data.avatar} />}
                label={data.label}
                onDelete={
                  data.label === "React" ? undefined : handleDelete(data)
                }
              />
            ) : (
              <Chip
                avatar={<Avatar alt="Avatar" src={data.avatar} />}
                label={data.label}
              />
            )}
          </ListItem>
        );
      })}
    </Paper>
  );
}
