import axios from "axios";
import { environment } from "../environments/environment";

export default class AdherentService {
  getAllAdherents = async () => {
    return axios.get(environment.API_URL + "adherents");
  };
  getDisableAdherent = async () => {
    return axios.get(environment.API_URL + "adherents/disable");
  };
  generateUserName(data) {
    return axios.post(environment.API_URL + `adherents/generateUsername`, data);
  }
  addAdherent = async (data) => {
    return axios.post(environment.API_URL + "adherents/create", data);
  };

  updateAdherent = async (adherentId, data) => {
    return axios.post(
      environment.API_URL + `adherents/update/${adherentId}`,
      data
    );
  };

  updateStatus = async (adherentId, data) => {
    return axios.post(
      environment.API_URL + `adherents/updateStatus/${adherentId}`,
      data
    );
  };

  getResponses = async (adherentId) => {
    return axios.get(
      environment.API_URL + "adherents/" + adherentId + "/responses"
    );
  };

  getAdherentInformation = async (adherentId) => {
    return axios.get(environment.API_URL + "adherents/v1/" + adherentId);
  };

  getAdherentDetails = async (adherentId) => {
    return axios.get(
      environment.API_URL + "adherents/v1/" + adherentId + "/details"
    );
  };
  getAvailableAdherents = async () => {
    return axios.get(environment.API_URL + "adherents/available");
  };
  getCoachsByAdherent = async (adherentId) => {
    return axios.get(
      environment.API_URL + `coach-adherent/${adherentId}/coachs`
    );
  };
}
