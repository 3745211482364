import axios from "axios";
import { environment } from "../environments/environment";

export default class AdherentSubscriptionService {
  getSubscriptionWeeks = async (adherentId) => {
    return axios.get(
      environment.API_URL +
        `adherent-subscriptions/${adherentId}/weeks`
    );
  };
}
