import { useEffect, useState } from "react";
import Modal from "../modal/modal";
import BreadcrumbsLink from "../../lib/breadcrumbs/breadcrumbs";
import InputGroup from "../../lib/input-group/input-group";
import SelectForm from "../../lib/select/select";
import { villes } from "../../../utils/variables/villesOptions";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CoachAdherentService from "../../../services/coachAdherent.service";
import ChipsArray from "../../lib/chips/chipsArray";
import showAlert from "../../lib/Alerts/sweetAlert";
import { environment } from "../../../environments/environment";
import "../../assets/css/global.css";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ArchiveDetailsCoach({
  coach = {},
  closeModalFunction,
}) {
  const coachAdherentService = new CoachAdherentService();
  const baseUrl = environment.API_URL;
  const [userData, setUserData] = useState(coach);
  const [value, setValue] = useState(0);
  const [imageUrl] = useState(baseUrl + "users/" + coach._id + "/image");
  const [adherents, setAdherents] = useState([]);
  const [chipData, setChipData] = useState([]);
  const CloseModaldetailsUser = () => {
    closeModalFunction();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const getAffectedAdherents = async () => {
    try {
      await coachAdherentService
        .getAdherentsAffected(coach._id)
        .then((response) => {
          const data = response.data.map((adherent) =>
            formataffectedAdherentData(adherent)
          );
          setChipData(data);
          setAdherents(data);
        })
        .catch(() => {
          showAlert({
            title: "Oups!! erreur",
            iconType: "error",
            text: "Une erreur d'affichage est survenue",
            cancelButtonText: "ok",
          });
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const formataffectedAdherentData = (adherent) => {
    return {
      avatar: `${baseUrl}adherents/${adherent.adherent._id}/image`,
      label: `${adherent.adherent.firstname} ${adherent.adherent.lastname} (${adherent.adherent.username})`,
      key: adherent._id,
    };
  };
  useEffect(() => {
    getAffectedAdherents();
  }, []);
  return (
    <>
      <Modal
        onCancelFunction={CloseModaldetailsUser}
        onCloseFunction={CloseModaldetailsUser}
        submitFunction={CloseModaldetailsUser}
        cancelText="retour"
        dialogTitle={
          <BreadcrumbsLink
            firstLinkTitle="Archive"
            href="/archive"
            activatedLinkTitle="détails d'un coach"
            color="titleModal"
          />
        }
        applyTitle=""
        isOpened={true}
        dialogContentChild={
          <>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="Vertical tabs example"
                sx={{
                  "& .MuiTabs-indicator": {
                    height: "7px",
                    backgroundColor: "#14CA81",
                  },
                  "& .Mui-selected": {
                    color: "black !important",
                    fontSize: "14px",
                    fontWeight: "600",
                    width: "100%",
                  },
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "space-between",
                  },
                }}
              >
                <Tab label="Informations générales" {...a11yProps(0)} />
                <Tab
                  label={`Adhérents(${adherents.length})`}
                  {...a11yProps(1)}
                />
              </Tabs>
              <TabPanel value={value} index={0}>
                <div className="row">
                  <div className="col-6">
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <input
                          type="file"
                          id="imageUpload"
                          accept=".png, .jpg, .jpeg"
                        />
                      </div>
                      <div className="avatar-preview">
                        <div
                          id="clock"
                          style={{ backgroundImage: `url(${imageUrl})` }}
                        ></div>
                      </div>
                    </div>
                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Nom"
                      type="text"
                      name="lastname"
                      value={userData.lastname}
                    />

                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Prénom"
                      type="text"
                      name="firstname"
                      value={userData.firstname}
                    />

                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Téléphone"
                      type="tel"
                      value={userData.phone}
                      name="phone"
                    />
                  </div>
                  <div className="col-6">
                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Email"
                      type="email"
                      value={userData.email}
                      name="email"
                    />

                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Username"
                      type="text"
                      value={userData.username}
                      name="username"
                    />
                    <SelectForm
                      disabled={true}
                      value={""}
                      name="country"
                      labelFilter="Tunisie"
                      classNames="addUser_select"
                      titleSelect="Pays"
                    />
                    <SelectForm
                      disabled={true}
                      options={villes}
                      name="city"
                      value={userData.city}
                      labelFilter="Ville"
                      classNames="addUser_select"
                      titleSelect="Ville"
                      MenuProps={MenuProps}
                    />

                    <InputGroup
                      disabled={true}
                      classNames="form-control-input-group-add"
                      title="Adresse"
                      type="text"
                      name="address"
                      value={userData.address}
                    />
                  </div>
                </div>
              </TabPanel>

              <TabPanel value={value} index={1}>
                {chipData.length > 0 ? (
                  <>
                    <div className="pb-2 fw-bolder fs-6 text-black">
                      Adhérants afféctés : {chipData.length}
                    </div>
                    <ChipsArray
                      chipData={chipData}
                      setChipData={setChipData}
                      avatar={chipData}
                      diplayDelete={false}
                    />
                  </>
                ) : (
                  <h2
                    className="loader"
                    data-text="il n'y a pas des adherents affectés à ce coach......."
                  >
                    il n'y a pas des adherents affectés à ce coach.......
                  </h2>
                )}
              </TabPanel>
            </Box>
          </>
        }
      />
    </>
  );
}
