import { useEffect, useState } from "react";
import "./comment.css";
import {  SendFill } from "react-bootstrap-icons";
import CommentService from "../../../services/comments.service";
import showAlert from "../Alerts/sweetAlert";
export default function ChatBot({ affectedExerciceId, adherentId }) {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const commentService = new CommentService();

  const getConversationByExercice = async () => {
    try {
      await commentService
        .getConversation(affectedExerciceId)
        .then((response) => {
          setMessages(response.data);
        });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };

  const submitChat = async () => {
    if (typeof inputValue !== "string" || !inputValue.trim()) {
      return;
    }

    try {
      setIsTyping(true);

      const payload = {
        message: inputValue,
        exercice: affectedExerciceId,
        by: "User",
      };

      await commentService.addComment(payload);
      setInputValue("");
      setIsTyping(false);

      const response = await commentService.getConversation(affectedExerciceId);
      setMessages(response.data);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };
  useEffect(() => {
    getConversationByExercice();
  }, []);
  return (
    <div className="container">
      <div className="chat">
        <div className="chat-box">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender.username}`}>
              <span className="party username_conversation fw-bolder">
                {message.sender.username} :{" "}
              </span>
              <span className="text text-black">{message.message}</span>
            </div>
          ))}
          {isTyping && (
            <div className="message chatbot typing">coach en train d'écrire...</div>
          )}
        </div>
        <div className="input d-flex justify-content-start align-items-center">
          <input
            type="text"
            placeholder="Écrivez votre commentaire ici"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && submitChat()}
          />
          <SendFill onClick={submitChat} className="icon_send" />
        </div>
      </div>
    </div>
  );
}
