import axios from "axios";
import { environment } from "../environments/environment";

export default class CoachAdherentService {
  affectAdherentToCoach = async (data) => {
    return axios.post(environment.API_URL + "coach-adherent/affect", data);
  };
  getAdherentsAffected = async (coachId) => {
    return axios.get(environment.API_URL + `coachs/${coachId}/adherents`);
  };
  disaffectAdherent = async (adherentId, coachId) => {
    return axios.post(
      environment.API_URL +
        `coach-adherent/disaffected/${adherentId}/${coachId}`
    );
  };
}
