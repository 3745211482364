import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExerciceAdherentService from "../../services/exerciceAdherent.service";
import { environment } from "../../environments/environment";
import Planning from "../components/adherent/planning";
import Button from "../lib/button/button";
import AffectExercice from "../components/exerciceAdherent/affectExercice";
import { GearFill, X } from "react-bootstrap-icons";
import EditAffectExercice from "../components/exerciceAdherent/editAffectExercice";
import showAlert from "../lib/Alerts/sweetAlert";
import AdherentService from "../../services/adherent.service";
import AdherentSubscriptionService from "../../services/adherentSubscription.service";
import loadingDataImg from "../assets/images/LoadingData.png";
import BreadcrumbsLink from "../lib/breadcrumbs/breadcrumbs";
const exerciceAdherentService = new ExerciceAdherentService();
const adherentService = new AdherentService();
const adherentSubscriptionService = new AdherentSubscriptionService();

const baseUrl = environment.API_URL;

export default function AdherentPlanning({}) {
  let { adherentId } = useParams();
  const [exercices, setExercices] = useState({});
  const [username, setUsername] = useState("");
  const [openModal, setOpenModal] = useState("");
  const [adherentData, setAdherentData] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(1);

  const [selectedDay, setSelectedDay] = useState("monday");
  const handleSelectedChange = (newValue) => {
    setSelectedDay(newValue);
  };
  const getAffectedExercices = async () => {
    try {
      const response = await exerciceAdherentService.getExerciceAffected(
        adherentId,
        currentWeek
      );
      setUsername(response.data.adherent);
      const formattedData = {};
      for (const key in response.data.exercices) {
        if (Object.hasOwnProperty.call(response.data.exercices, key)) {
          const exercice = response.data.exercices[key];
          if (!formattedData[key]) {
            formattedData[key] = [];
          }
          const formattedExercices = [];
          for (const index in exercice) {
            if (Object.hasOwnProperty.call(exercice, index)) {
              const item = exercice[index];
              const statusTextClass =
                item.isDone === "no"
                  ? "Non terminé "
                  : item.isDone === "yes"
                  ? "Terminé"
                  : "";
              const StatusStyleClass =
                item.isDone === "no"
                  ? "status_non_completed"
                  : item.isDone === "yes"
                  ? "status_done"
                  : "";
              formattedExercices.push([
                <img
                  src={
                    baseUrl +
                    "exercices/" +
                    item.exercice._id +
                    "/ExplicatifImage"
                  }
                  className="affect_adherent_img"
                />,
                item.exercice.name,
                item.time != "00:00"  ? item.time : "-",
                item.sets,
                item.repeat > 0 ? item.repeat : "-",
                item.weight > 0 ? `à partir de ${item.weight} Kg` : "-",
                <div className={StatusStyleClass}>{statusTextClass}</div>,
                <div className="d-flex justify-content-evenly">
                  <X
                    className="icon_cancelExerciceCategories "
                    onClick={() => disaffected(item._id)}
                  />
                  <div className="circle_config">
                    <GearFill
                      className="icon_affectedExerciceConfig"
                      onClick={() => {
                        setOpenModal(
                          <EditAffectExercice
                            closeModalFunction={closeModal}
                            onSuccessFunction={getAffectedExercices}
                            username={response.data.adherent}
                            adherentId={adherentId}
                            selectedDay={selectedDay}
                            affectedExerciceId={item._id}
                          />
                        );
                      }}
                    />
                  </div>
                </div>,
              ]);
            }
          }
          formattedData[key] = formattedExercices;
        }
        closeModal();
        setExercices(formattedData);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  async function disaffected(affectedExerciceId) {
    try {
      await exerciceAdherentService.deleteAffectedExercice(affectedExerciceId);
      showAlert({
        title: "vous êtes sûr ?",
        iconType: "question",
        confirmButtonText: "oui",
        cancelButtonText: "Non",
        showCancelButton: true,
        titleSuccess: "Exercice desaffecté avec succées",
        iconSuccess: "success",
        confirmFunction: async () => {
          getAffectedExercices();
        },
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur est survenue lors de la désaffectation",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  }
  const getDetailsAdherent = async () => {
    try {
      await adherentService.getAdherentDetails(adherentId).then((response) => {
        const data = response.data;
        data.dayEnum = data.days.map((day) => {
          return day === "monday"
            ? "Lun"
            : day === "tuesday"
            ? "Mar"
            : day === "wednesday"
            ? "Mer"
            : day === "thursday"
            ? "Jeu"
            : day === "friday"
            ? "Ven"
            : day === "saturday"
            ? "Sam"
            : day === "sunday"
            ? "Dim"
            : "";
        });
        setAdherentData(data);
      });
    } catch (error) {
      showAlert({
        title: "Oups!! erreur",
        iconType: "error",
        text: "Une erreur d'affichage est survenue",
        cancelButtonText: "ok",
      });
      console.log("error", error);
    }
  };
  const closeModal = () => {
    setOpenModal("");
  };
  const getWeeksOfSubscription = async () => {
    try {
      const response = await adherentSubscriptionService.getSubscriptionWeeks(
        adherentId
      );
      const allWeeks = [];
      console.log(response.data.weeks);
      for (let week = 1; week <= response.data.weeks; week++) {
        allWeeks.push(week);
      }
      setWeeks(allWeeks);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getAffectedExercices();
  }, [currentWeek]);
  useEffect(() => {
    getDetailsAdherent();
    getWeeksOfSubscription();
  }, []);

  return (
    <>
      <BreadcrumbsLink
        firstLinkTitle="Adhérents"
        secondLinkTitle="modifier adhérent"
        href="/adherents"
        activatedLinkTitle="modifier le planning  "
        color="titleModal"
      />
      {adherentData !== null ? (
        <div className="pt-5">
          <Planning
            exercices={exercices}
            onTabClickFunction={handleSelectedChange}
            adherentDays={adherentData?.days}
            weeks={weeks}
            currentWeek={currentWeek}
            setCurrentWeek={setCurrentWeek}
            className="mt-3"
          />
        </div>
      ) : (
        <div className="text-center">
          <img className="w-50" src={loadingDataImg} />
        </div>
      )}
      <div className="d-flex justify-content-center">
        <Button
          title={"Ajouter un exercice"}
          classNames="add_exercice mx-5 mt-3 fw-bolder fs-5"
          onClickFunction={() => {
            setOpenModal(
              <AffectExercice
                closeModalFunction={closeModal}
                onSuccessFunction={() => {
                  getAffectedExercices();
                }}
                username={username}
                adherentId={adherentId}
                week={currentWeek}
                selectedDay={selectedDay}
                displayedExercices={exercices[selectedDay].map(
                  (item) => item[1]
                )}
              />
            );
          }}
        />
      </div>
      {openModal}
    </>
  );
}
